import React, { Component } from "react";
import Calendar from "react-calendar";
import "./schedule-input-calendar.scss";

const { formatDate } = require('../helpers/GeneralHelpers');

export default class DailyBICalendar extends Component {

  constructor(props){
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }


  onChangeHandler(date){
    var newDate = formatDate(date);
    this.props.history.push(`${this.props.match.url}/${newDate}`);
  }

  render() {
    return (
      <div className="daily-bi-calendar">
        <Calendar 
          value={new Date()}
          onChange={this.onChangeHandler} 
          calendarType="US"/>
      </div>
    );
  }
}
