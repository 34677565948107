import React, { Component } from "react";

import '../../css/components/daily-bi-table-row.scss';

import SettingVariables from "../../SettingVariables";

import LoadingPuffIcon from "../icons/LoadingPuffIcon";

import { getLaborChartRecord } from "../helpers/LaborChartHelpers";

import BIQueries from "../queries/BIQueries";
import DailyBIActions from "../actions.js/DailyBIActions";
// import { CostExplorer } from "aws-sdk/clients/all";

export default class DailyBITableRow extends Component {

  constructor(props){
    super(props);

    this.inputOnChangeHandler = this.inputOnChangeHandler.bind(this);
    this.calculatePortalFailures = this.calculatePortalFailures.bind(this);

    this.state = {
      loading: false,
      dates: [],
      actualSales: '',
      projectedSales: '',
      actualChartedHours: '',
      totalPortalOrdersEligible: '',
      percentInPortalOnTime: '',
      percentInPortalBefore: '',
      portalFailures: '',
      cash: '',
      customerComplaints: '',
    }

    DailyBIActions.calculateActualSales({ storeId: this.props.row.id, date: this.props.date}).then(result => {
      if (result && result.length > 0) {
        const actualSales = parseFloat(result[0]["SUM(nettransactions)"]).toFixed(2);
        const laborChartRecord = getLaborChartRecord(actualSales);

        this.props.updateRow(this.props.storeName, this.props.date, { 'Actual Sales': actualSales, 'Actual Charted Hours': laborChartRecord.Hours})
        this.setState({
          actualSales: actualSales,
          actualChartedHours: laborChartRecord.Hours,
        })
      }
    })

    // Find projectedSales
    BIQueries.find({ idAPI: SettingVariables.idAPIConverter(43), BI_externalid: this.props.storeName, BI_Date: this.props.date}).then(result => {
      
      let projectedSales = '';
      if(result.length > 0){
        projectedSales = result[0].BI_amount;
      }

      this.props.updateRow(this.props.storeName, this.props.date, { 'Projected Sales': projectedSales })


      this.setState({
        projectedSales: projectedSales,
      })
    })

    // Find projectedSales for testing
    // BIQueries.find({ idAPI: 143, BI_externalid: this.props.storeName, BI_Date: this.props.date}).then(result => {
      
    //   let projectedSales = '';
    //   if(result.length > 0){
    //     projectedSales = result[0].BI_amount;
    //   }

    //   this.props.updateRow(this.props.storeName, this.props.date, { 'Projected Sales': projectedSales })


    //   this.setState({
    //     projectedSales: projectedSales,
    //   })
    // })

    const idAPIArray = [SettingVariables.idAPIConverter(58), SettingVariables.idAPIConverter(51), SettingVariables.idAPIConverter(52), SettingVariables.idAPIConverter(50), SettingVariables.idAPIConverter(54), SettingVariables.idAPIConverter(57)];

    // Update total portal orders eligible, % in portal on time, % in portal early, # of portal failures, cash, and customer complaints
    BIQueries.find({BI_externalid: props.storeName, BI_Date: props.date, idAPI: idAPIArray}).then(result => {
      if(result && result.length > 0) {

        var updateInfo = {};
        result.forEach(r => {
          updateInfo[r.BI_fieldname] =  r.BI_amount || r.BI_qty;
        })


        // Update percent value for % rows 
        const fieldNameArray = result.map(r => r.BI_fieldname);

        if(fieldNameArray.indexOf("Total Portal Eligible Transactions") > -1){
          if(fieldNameArray.indexOf("% Put into Portal on Time") > -1){
            updateInfo["% Put into Portal on Time percent"] = ((updateInfo["% Put into Portal on Time"] / updateInfo["Total Portal Eligible Transactions"]) * 100).toFixed(4);

            if(fieldNameArray.indexOf("% Put into Portal Before Promise Time") > -1){
              // updateInfo["% Put into Portal Before Promise Time percent"] = (updateInfo["% Put into Portal Before Promise Time"] / updateInfo["Total Portal Eligible Transactions"] / updateInfo["% Put into Portal on Time percent"]).toFixed(4);

              updateInfo["% Put into Portal Before Promise Time percent"] = ((updateInfo["% Put into Portal Before Promise Time"] / updateInfo["% Put into Portal on Time"])*100).toFixed(4)
            }
          }
        }
        
        this.props.updateRow(this.props.storeName, this.props.date, updateInfo);
      }
    }) 
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.row['Total Portal Eligible Transactions'] && this.props.row['% Put into Portal on Time percent']){
      if(this.props.row['Total Portal Eligible Transactions'] !== prevProps.row['Total Portal Eligible Transactions'] || this.props.row['% Put into Portal on Time percent'] !== prevProps.row['% Put into Portal on Time percent']){
        this.calculatePortalFailures()
      }
    }
  }

  calculatePortalFailures(){
    const portalFailures = (this.props.row['Total Portal Eligible Transactions'] * (1 - this.props.row['% Put into Portal on Time percent'] * .01));
    this.props.updateRow(this.props.storeName, this.props.date, { 'Portal Failures': Math.round(portalFailures)})
  }


  inputOnChangeHandler(e){
    this.setState({
      [e.target.id]: e.target.value,
    })

    var updateInfo = {}
    var value = e.target.value;
    // Changes percent values to a decimal
    // if(e.target.id.indexOf("%") !== -1) value = value * .01;

    updateInfo[e.target.id] = value;
    const rowInfo = this.props.row;

    // if(e.target.id === "% Put into Portal Before Promise Time percent") {
    //   const newId = "% Put into Portal Before Promise Time";

    //   console.log('% Put into Portal Before Promise Time');
    // } else if (e.target.id === "% Put into Portal on Time percent"){
    //   const newId = "% Put into Portal on Time";

    //   debugger;
    //   if(rowInfo["Total Portal Eligible Transactions"] || rowInfo["Total Portal Eligible Transactions"] === 0) {
    //     debugger;
    //   }
    //   console.log('% put into portal on time');
    // }

    this.props.updateRow(this.props.storeName, this.props.date, updateInfo)
  }

  render(){
    if(this.state.loading){
      return (
        <tr className="daily-bi-table-row loading">
          <td>
            <LoadingPuffIcon hexColor="#0077D7"/>
          </td>
        </tr>
      )
    } else {
      return (
        <tr className="daily-bi-table-row">
          <td className="daily-bi-table-row__date">
            {this.props.row.storeName}
          </td>
          <td className="daily-bi-table-row__entry">
            {this.props.row['Actual Sales']}
          </td>
          <td className="daily-bi-table-row__entry">
            {this.state.projectedSales}
          </td>
          <td className="daily-bi-table-row__entry">
            {this.props.row['Actual Charted Hours'] || ''}
          </td>
          {/* <td className="daily-bi-table-row__entry">
            <input type="number" id="Total Portal Eligible Transactions" value={this.props.row['Total Portal Eligible Transactions'] == null ? '' : this.props.row['Total Portal Eligible Transactions']} onChange={this.inputOnChangeHandler}/>
          </td>
          <td className="daily-bi-table-row__entry">
            The * 100 is to make the  stored number (percent as a decimal) be viewable as a percent and also for input in percent form too.
            <input type="number" id="% Put into Portal on Time percent" value={this.props.row['% Put into Portal on Time percent'] == null ? '' : this.props.row['% Put into Portal on Time percent']} onChange={this.inputOnChangeHandler}/>
          </td>
          <td className="daily-bi-table-row__entry">
            The is to make the stored number (percent as a decimal) be viewable as a percent and also for input in percent form too.
            <input type="number" id="% Put into Portal Before Promise Time percent" value={this.props.row['% Put into Portal Before Promise Time percent'] == null ? '' :  this.props.row['% Put into Portal Before Promise Time percent']} onChange={this.inputOnChangeHandler}/>
          </td>
          <td className="daily-bi-table-row__entry">
            {this.props.row['Portal Failures']}
          </td> */}
          <td className="daily-bi-table-row__entry">
            <input type="number" id="Cash Over/Under" value={this.props.row['Cash Over/Under'] == null ? '' : this.props.row['Cash Over/Under']} onChange={this.inputOnChangeHandler}/>
          </td>
          <td className="daily-bi-table-row__entry">
            <input type="number" id="Customer Complaints" value={this.props.row['Customer Complaints'] == null ? '' : this.props.row['Customer Complaints']} onChange={this.inputOnChangeHandler}/>
          </td>
        </tr>
      )
    }
    
  }
}