import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./NewBiEntry.css";
import sha256 from 'crypto-js/sha256';
import { API } from "aws-amplify";
import _ from 'lodash';

export default class NewBiEntry extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      idApi: 0,
      biExternalid: '',
      biExternalid2: '',
      biExternalid3: '',
      biExternalid4: '',
      biDate: '',
      biFieldname: '',
      biDebit: '',
      biCredit: '',
      biAmount: '',
      biQty: ''
    };
  }

  validateForm() {
    return this.state.idApi > 0 && this.state.biDate !== '' && /^[12][09][0129][0-9]-[01][0-2]-[0-3][0-9].[0-2][0-9]:[0-5][0-9]$/.test(this.state.biDate) && this.validateFloat(this.state.biDebit) && this.validateFloat(this.state.biCredit) && this.validateFloat(this.state.biAmount) && this.validateStore(this.state.biExternalid);
  }

  validateStore(store) {
    return store === '3394-0001 Grand Island' || store === '3394-0002 North Platte' || store === '3394-0003 Kearney' || store === '3394-0004 Columbus' || store === '3394-0005 Norfolk';
  }

  validateFloat(n) {
    if (n === '') {
      return true;
    }
    // This won't allow any whole numbers. Is that on purpose?
    return Number(n) === parseFloat(n) && n % 1 !== 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const includeBiExternalid = this.state.biExternalid !== '';
    const includeBiExternalid2 = this.state.biExternalid2 !== '';
    const includeBiExternalid3 = this.state.biExternalid3 !== '';
    const includeBiExternalid4 = this.state.biExternalid4 !== '';
    const includeBiFieldname = this.state.biFieldname !== '';
    const includeBiDebit = this.state.biDebit !== '';
    const includeBiCredit = this.state.biCredit !== '';
    const includeBiAmount = this.state.biAmount !== '';
    const includeBiQty = this.state.biQty !== '';

    const store = this.state.biExternalid;
    let query = "INSERT INTO `BI` (`idBI`, `idAPI`, `BI_Date`";
    let biMsg = _.clone(this.state.idApi);
    let values = "', " + this.state.idApi + ", '" + this.state.biDate + ":00'";
    let duplicateKey = " ON DUPLICATE KEY UPDATE"
    if (includeBiExternalid) {
      query = query + ",`BI_externalid`";
      biMsg = biMsg + " " + this.state.biExternalid;
      values = values + ", '" + this.state.biExternalid + "'";
    }
    if (includeBiExternalid2) {
      query = query + ",`BI_externalid2`";
      biMsg = biMsg + " " + this.state.biExternalid2;
      values = values + ", '" + this.state.biExternalid2 + "'";
    }
    if (includeBiExternalid3) {
      query = query + ",`BI_externalid3`";
      biMsg = biMsg + " " + this.state.biExternalid3;
      values = values + ", '" + this.state.biExternalid3 + "'";
    }
    if (includeBiExternalid4) {
      query = query + ",`BI_externalid4`";
      biMsg = biMsg + " " + this.state.biExternalid4;
      values = values + ", '" + this.state.biExternalid4 + "'";
    }
    if (includeBiFieldname) {
      query = query + ",`BI_fieldname`";
      biMsg = biMsg + " " + this.state.biFieldname;
      values = values + ", '" + this.state.biFieldname + "'";
    }
    if (includeBiDebit) {
      query = query + ",`BI_debit`";
      values = values + ", " + this.state.biDebit;
      duplicateKey = duplicateKey + " BI_debit = VALUES(BI_debit),";
    }
    if (includeBiCredit) {
      query = query + ",`BI_credit`";
      values = values + ", " + this.state.biCredit;
      duplicateKey = duplicateKey + " BI_credit = VALUES(BI_credit),";
    }
    if (includeBiAmount) {
      query = query + ",`BI_amount`";
      values = values + ", " + this.state.biAmount;
      duplicateKey = duplicateKey + " BI_amount = VALUES(BI_amount),";
    }
    if (includeBiQty) {
      query = query + ",`BI_qty`";
      values = values + ", " + this.state.biQty;
      duplicateKey = duplicateKey + " BI_qty = VALUES(BI_qty),";
    }
    biMsg = biMsg + " " + this.state.biDate + ":00";
    biMsg = sha256(biMsg);
    query = query + ") VALUES ('" + biMsg + values + ")";
    
    if (duplicateKey !== " ON DUPLICATE KEY UPDATE" && duplicateKey.substr(duplicateKey.length - 1) === ",") {
      query = query + duplicateKey.substr(0,duplicateKey.length - 1);
    }

    try {
      await this.singleEntry(query, store);
      this.props.history.push("/bientry");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  singleEntry(query, store) {
    debugger;
    const dateTimeStamp = + new Date();
    let storeId = 0;
    switch (store) {
      case '3394-0001 Grand Island':
        storeId = 4;
        break;
      case '3394-0002 North Platte':
        storeId = 6;
        break;
      case '3394-0003 Kearney':
        storeId = 2;
        break;
      case '3394-0004 Columbus':
        storeId = 5;
        break;
      case '3394-0005 Norfolk':
        storeId = 3;
        break;
      default:
        return;
    }

    // let payload = {"query": query}
    let payload = {"queries": [query], "store": storeId, "datestamp": dateTimeStamp};
    debugger;
    // for the select only api use "/single/untitled" instead of "/untitled"
    return API.post("mi6-bi", "/untitled", { 
      body: payload,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div className="NewBiEntry">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="idApi" bsSize="large">
            <ControlLabel>idAPI</ControlLabel>
            <FormControl
              autoFocus
              type="number"
              min="0"
              value={this.state.idApi}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biExternalid" bsSize="large">
            <ControlLabel>Store Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biExternalid}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biExternalid2" bsSize="large">
            <ControlLabel>BI_externalid2</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biExternalid2}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biExternalid3" bsSize="large">
            <ControlLabel>BI_externalid3</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biExternalid3}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biExternalid4" bsSize="large">
            <ControlLabel>BI_externalid4</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biExternalid4}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biDate" bsSize="large">
            <ControlLabel>BI_Date</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="yyyy-mm-dd hh:mm"
              value={this.state.biDate}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biFieldname" bsSize="large">
            <ControlLabel>BI_fieldname</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biFieldname}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biDebit" bsSize="large">
            <ControlLabel>BI_debit</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biDebit}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biCredit" bsSize="large">
            <ControlLabel>BI_credit</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biCredit}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biAmount" bsSize="large">
            <ControlLabel>BI_amount</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biAmount}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="biQty" bsSize="large">
            <ControlLabel>BI_qty</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.biQty}
              onChange={this.handleChange}
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Enter"
            loadingText="Sending Data…"
          />
        </form>
      </div>
    );
  }
}
