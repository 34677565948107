import React, { Component } from "react";


// Props 
//  -- strokeColor - must be a string
//  -- direction up, down, left or right. Default is right.

export default class XIcon extends Component {
  render(){
    return (
      <svg className="x-icon-svg" width={this.props.width || "12px"} height={this.props.width || "12px"} viewBox="0 0 46 46" strokeWidth={this.props.strokeWidth ? `${this.props.strokeWidth}px` : "5px"} stroke={this.props.color ? `${this.props.color}`:'black'}>
        <path d="M0,0 L46,46 M46,0 L0,46" id="Stroke-233"></path>
      </svg>
    )
  }
}