import _ from "lodash";

export function cloneObject(obj){
  var newObject = {};
  for(var key in obj){
    newObject[key] = obj[key];
  }
  return newObject;
}
export function cloneArray(array){
  var newArray = [];
  array.forEach(element => {
    var clonedElement;
    if(typeof element === "object"){
      clonedElement = cloneObject(element);
    } else {
      clonedElement = element;
    }
    newArray.push(clonedElement);
  })
  
  return newArray;
}

export function formatDate(date){
  var d = new Date(date),
  month = '' + (d.getMonth() + 1),
  day = '' + d.getDate(),
  year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}

export function formatMS(ms){
  if(typeof ms === "string") ms = parseInt(ms);
  return new Date(ms).toISOString().slice(11, -1);
}

export function arrayIncludes(targetArray, target){
  // If target doesn't exist
  if(!target) {
    return -1;
  }
  // If target is an object
  else if(typeof target === "object"){
    for(var i = 0; i < targetArray.length; i++){
      if(_.isEqual(targetArray[i], target)) {
        return i;
      }
    }
  }
  else {
    var index = targetArray.indexOf(target);
    if(index > -1){
      return index;
    }
  }
  return -1;
}

export function alphabeticalSort(a, b) {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function dateSort(a, b) {

}

export function generalSort(a, b) {

}