import Queries from './Queries';
import QueryHelpers from "../helpers/QueryHelpers";

const columns = ['netsales', 'storeid', 'bizdatesale'];

const OrderSummaryQueries = {
  find: function(params, targetColumns){
    
    const query = Queries.find("daily_summary", params, columns, targetColumns);

    return QueryHelpers.runSelectAPI(query);
  },
}

export default OrderSummaryQueries;