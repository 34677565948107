import React, { Component } from "react";

import "../../css/components/option-pill.scss";

import PlusIcon from "../icons/PlusIcon";
import XIcon from "../icons/XIcon";

export default class OptionPill extends React.Component {
  render(){
    return (
      <div className={`option-pill${this.props.selected ? " selected":""}`} onClick={this.props.onClick}>
        <div className="text">{this.props.text}</div>
        <div className="icon-wrapper">
          { this.props.selected ? 
            <XIcon/>
            :
            <PlusIcon/>
          }
        </div>
        
      </div>
    )
  }
}