import React, { Component, Fragment } from "react";
import moment from 'moment';

import "./Home.css";

import SettingVariables from "../../SettingVariables";

import Modal from '../components/Modal';
import CalendarDatePicker from '../components/CalendarDatePicker';

import { ActionResponseModal } from '../components/action-response-modal/ActionResponseModal';

import { API } from "aws-amplify";

export default class Home extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalState: null,
      showCalendar: false,
      recordCount: null,
    }
  }

  updateState = (stateName, value) => {
    this.setState({
      [stateName]: value,
    })
  }

  toggleShowCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar,
    })
  }

  generateQboEntries = () => {
    this.setState({
      modalState: 'loading',
    })

    API.post("mi6-bi", "/qbo-dailies").then(result => {      
      if (parseInt(result) >= 25 ) {
        this.setState({
          modalState: 'too-many-records',
          recordCount: parseInt(result),
        })
      } else {
        this.setState({ 
          modalState: 'skyvia-check',
          recordCount: parseInt(result),
        });
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
    });
  }

  singleDayProcess = (date) => {
    this.setState({
      showCalendar: false,
      modalState: 'loading',
    });
    const formattedDate = moment(date).format('YYYY-MM-DD');

    API.post("mi6-bi", "/qbo-dailies/single/untitled",{
      body: { "rundate": formattedDate }
    }).then(result => {
      if (parseInt(result) >= 25 ) {
        this.setState({
          modalState: 'too-many-records',
          recordCount: parseInt(result),
        })
      } else {
        this.setState({ 
          modalState: 'skyvia-check',
          recordCount: parseInt(result),
        });
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>>");
    })
  }

  closeModal = () => {
    this.setState({
      modalState: null,
    })
  }
  

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>MI6 Pizza</h1>
          <p>BI Actions and Forms</p>
          <p>{SettingVariables.mode}</p>
        </div>

        { this.props.isAuthenticated &&
          <div className="actions">
            <div className="header">
              Actions
            </div>
            <div className="actions-list">
              <div className="button" onClick={ this.generateQboEntries }>
                Generate QBO Entries
              </div>
              {/* <div className="button" onClick={ this.markComplete }>
                Mark Complete
              </div> */}
              <div className="button" onClick={ this.toggleShowCalendar }>
                Single Day Process
              </div>
            </div>
          </div>
        }
        

        { this.state.modalState &&
          <ActionResponseModal closeModal={ this.closeModal } modalState={ this.state.modalState } recordCount={ this.state.recordCount } updateState={ this.updateState } />
        }

        { this.state.showCalendar &&
          <Modal>
            <CalendarDatePicker onDateSelect={ this.singleDayProcess }/>
          </Modal>
        }
      </div>
    );
  }
}
