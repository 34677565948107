import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import DailyBITable from "../components/DailyBITable";
import DailyBICalendar from "../components/DailyBICalendar";


export default class DailyBI extends Component {

  render() {
    return (
      <div className="test">
        <Switch>
          <Route path={`/daily-bi/:date`} component={DailyBITable}/>
          <Route component={DailyBICalendar}/>
        </Switch>
      </div>
    );
  }
}
