import PeriodsQueries from 'js/queries/PeriodsQueries';
import OrderSummaryQueries from 'js/queries/OrderSummaryQueries';

import { formatDate } from 'helpers/GeneralHelpers';

export function getPastDates(date){
  return PeriodsQueries.find({date: date}).then(periods => {
    let dates = [];

    let oneWeekBack = new Date(periods[0].date + " 00:00");
    for (let i = 0; i < 6; i++) {
      oneWeekBack.setDate(oneWeekBack.getDate() - 7);
      let newDate = formatDate(oneWeekBack);
      dates.push(newDate);
    }

    return PeriodsQueries.find({year: periods[0].year - 1, day: periods[0].day}).then(oneYearAgo => {
      if(oneYearAgo.length > 0){
        dates.push(oneYearAgo[0].date)
      }
      return dates;
    })
    
  })

}

export function getPreviousSales(storeId, date){
  return getPastDates(date).then(result => {
    return OrderSummaryQueries.find({ bizdatesale: result, storeid: storeId }, ['oven_count', 'bizdatesale', 'storeid', 'oven_count_proj']).then(answer => {
      const lastYearsSales = findEarliestSummary(answer).oven_count;
      let average = 0;
      let count = 0;
      if(answer){
        for(let i = 0; i < answer.length; i++){
          if (answer[i].oven_count) {
            average = average + parseFloat(answer[i].oven_count);
            count++;
          }
        }
        
        average = average / count;
      }
      
      return {
        sysSalesProjected: average.toFixed(2),
        lastYearsSales: lastYearsSales,
      };
    });
  });
}

export function findEarliestSummary(salesSummaries){
  if(salesSummaries.length == 0) return null;
  var earliestSummary = salesSummaries[0];
  for(var i = 1; i < salesSummaries.length ; i++){
      var currentSummary = salesSummaries[i];
      if(new Date(currentSummary.bizdatesale) < new Date(earliestSummary.bizdatesale)){
          earliestSummary = currentSummary;
      }
  }

  return earliestSummary;
}
