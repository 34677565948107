import { API } from "aws-amplify";

const QueryHelpers = {
  createPayload: function(queries, store){
    const dateTimeStamp = + new Date();
    let storeId;
    switch (store) {
      case '3394-0001 Grand Island':
        storeId = 4;
        break;
      case '3394-0002 North Platte':
        storeId = 6;
        break;
      case '3394-0003 Kearney':
        storeId = 2;
        break;
      case '3394-0004 Columbus':
        storeId = 5;
        break;
      case '3394-0005 Norfolk':
        storeId = 3;
        break;
      default: 
        storeId = 0;
    }

    return {"queries": queries, "store": storeId, "datestamp": dateTimeStamp}
  },
  createSinglePayload: function(query, store){
    const dateTimeStamp = + new Date();

    let storeId;
    switch (store) {
      case '3394-0001 Grand Island':
        storeId = 4;
        break;
      case '3394-0002 North Platte':
        storeId = 6;
        break;
      case '3394-0003 Kearney':
        storeId = 2;
        break;
      case '3394-0004 Columbus':
        storeId = 5;
        break;
      case '3394-0005 Norfolk':
        storeId = 3;
        break;
      default: 
        storeId = 0;
    }
    return {"query": query, "store": storeId, "datestamp": dateTimeStamp};
  },
  
  runSelectAPI: function(query){
    // const dateTimeStamp = + new Date();
    // let payload = {"query": [query], "store": storeId, "datestamp": dateTimeStamp};
    let payload = this.createSinglePayload(query);
    // let payload = {"query": query, "store": 0, "datestamp": dateTimeStamp};
    // for the select only api use "/single/untitled" instead of "/untitled"
    return API.post("mi6-bi", "/single/untitled", { 
      body: payload,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
      // this.setState({ isLoading: false });
    });
  },

  runInsertAPI: function(queries, store){
    let payload = this.createPayload(queries, store);
    return API.post("mi6-bi", "/untitled", { 
      body: payload,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
    });
  }
}

export default QueryHelpers;
