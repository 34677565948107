import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import InventoryManagement from "../components/InventoryManagement";

export default class Inventory extends Component {
  render(){
    return (
      <div>
        <Switch>
          <Route component={InventoryManagement}/>
        </Switch>
      </div>
    )
  }
}