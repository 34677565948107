import React, { Component } from "react";

import "css/global/buttons.scss";

import "css/components/ScheduleInputTable.scss";

import ChevronIcon from "js/icons/ChevronIcon";

import { formatDate, cloneArray } from "helpers/GeneralHelpers";

import StoreQueries from 'js/queries/StoreQueries';
// import PeriodsQueries from 'js/queries/PeriodsQueries';

import { TableRow } from './components/table-row/TableRow';
import BIQueries from "js/queries/BIQueries";
import ScheduleInputHelpers from "js/helpers/ScheduleInputHelpers";

import { QueryHelpers } from "queries/QueryHelpers";
import { StandardQueries } from "queries/StandardQueries";

export class OvenScheduleTable extends Component {

  constructor(props){
    super(props);

    this.getStores = this.getStores.bind(this);
    this.navigateBackToCalendar = this.navigateBackToCalendar.bind(this);
    this.setSelectedStore = this.setSelectedStore.bind(this);
    this.saveEdits = this.saveEdits.bind(this);
    this.updateRow = this.updateRow.bind(this);


    var dates = [this.props.match.params.date];
    var nextDay = new Date(this.props.match.params.date + " 00:00");
    for (var i = 0; i < 6; i++) {
      nextDay.setDate(nextDay.getDate() + 1);
      let newDate = formatDate(nextDay);
      dates.push(newDate);
    }

    this.state = {
      tableRows: [],
      stores: [],
      selectedStore: [],
      ovenCountLaborChart: [],
      dates: dates,
      disableSaveButton: true,
    }

    this.getOvenCountLabor();
    this.getStores();
  }

  componentDidUpdate(){
    if(this.state.dates.length > 0 && this.state.stores.length > 0 && this.state.tableRows.length === 0){
      let stores = this.state.stores;
      let dates = this.state.dates;
      var tableRows = [];

      for(let i = 0; i < stores.length; i++){
        for (let j = 0; j < dates.length; j++){
          tableRows.push({ store: stores[i], storeName: stores[i].stores_name, date: dates[j] });
        }
      }

      this.setState({
        tableRows: tableRows,
      })
    }
  }

  saveEdits(){
    ScheduleInputHelpers.formatDataForCreate(this.state.tableRows).then(result => {
      result.forEach(r => {
        if (r.data.length > 0) {
          BIQueries.create(r.data);
        }
      })
      this.setState({
        disableSaveButton: true,
      })
    })
  }

  navigateBackToCalendar(){
    this.props.history.push(`/oven-schedule-input`);
  }

  updateRow(storeName, date, updateInfo){
    var newTableRows = cloneArray(this.state.tableRows);
    var targetRow = newTableRows.filter(element => element.storeName === storeName && element.date === date)[0];

    for(var key in updateInfo){
      if(typeof updateInfo[key] === "string") updateInfo[key] = parseFloat(updateInfo[key]);
      targetRow[key] = updateInfo[key];
    }

    this.setState({
      tableRows: newTableRows,
      disableSaveButton: false,
    })
  }

  getOvenCountLabor() {
    QueryHelpers.runSelectAPI(StandardQueries.find('Labor_Chart_oc')).then(result => {
      this.setState({
        ovenCountLaborChart: result,
      })
    })
  }

  getStores(){
    StoreQueries.find().then(result => {
      this.setState({
        stores: result,
        selectedStore: [result[0]],
      });
    })
  }

  setSelectedStore(e){
    const selectedStore = this.state.stores.filter(store => store.stores_name === e.target.value);
    this.setState({
      selectedStore,
    })
  }

  render() {
    if (this.state.tableRows.length === 0 || this.state.stores.length === 0 || this.state.ovenCountLaborChart.length === 0) {
      return (
        <div>
          Calm Down Taaaaaaasha.
        </div>
      )
    }
    const storeRows = this.state.selectedStore.map((store, index) => {
      const {
        stores_name: storeName,
      } = store;
      if (!store || store === '' || store === null) return null;
      var currentStoreRows = this.state.tableRows.filter(tableRow => tableRow.storeName === storeName);
      const tableRows = currentStoreRows.map((row, index) => {
        // Used to limit the number of rows shown for testing. Remove when not testing
        // if (index !== 0) return null;
        return (
          <TableRow updateRow={ this.updateRow } key={ index } tableRow={ row } store={ row.store } storeName={ storeName } date={ row.date } ovenCountLaborChart={ this.state.ovenCountLaborChart }/>
        )
      })
      return (
        <div key={storeName} className="store-index__store-row">
          <div className="store-row__name">{storeName}</div>
          {tableRows}
        </div>
      )
    })

    const storeList = this.state.stores.map(store => {

      const {
        stores_name: storeName,
      } = store;
      return (
        <option key={storeName}>{storeName}</option>
      )
    })

    return (
      <div className="schedule-input-table">
        <div className="page-actions">
          <div className="schedule-input-table__back-button" onClick={this.navigateBackToCalendar}>
            <ChevronIcon  direction="left"/>
            Back to Date Select
          </div>
          <div>
            <select onChange={this.setSelectedStore}>
              { storeList }
            </select>
          </div>
        </div>
        
        <div className="schedule-input-table__store-index">
          <div className="store-index__headers">
            <div>Store and Date</div>
            <div>Sys Count Projected</div>
            <div>Last Year Count</div>
            <div>Projected Oven Count</div>
            <div>Charted Hours</div>
            <div>Scheduled Hours</div>
          </div>
          {storeRows}
        </div>

        <div className={`button schedule-input-table__save-edits${this.state.disableSaveButton ? ' disabled':''}`} onClick={this.saveEdits}>
          Save Edits
        </div>

      </div>
    );
  }
}


// Math.ceil(number/ 50 ) * 50 - 50;