import React from "react";

import "../../css/components/search-input.scss";

export default class SearchInput extends React.Component {
  constructor(props){
    super(props);

    this.searchInputHandler = this.searchInputHandler.bind(this);
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this);

    this.state = {
      searchInput: "",
    }
  }

  searchInputHandler(e){
    this.setState({
      searchInput: e.target.value,
    })
  }

  onKeyUpHandler(e){
    if(e.key === "Enter") {
      this.props.onEnterHandler(e.target.value);
      this.setState({
        searchInput: "",
      })
    }
  }

  render(){
    return (
      <div className="search-input">
        <input value={this.state.searchInput} onChange={this.searchInputHandler} onKeyUp={this.onKeyUpHandler}/>
      </div>
    )
  }
}