import React from "react"

import "../../css/components/non-standard-tickets-table-row.scss";

export default class NonStandardTicketsTableRow extends React.Component {
  constructor(props){
    super(props);
    this.onQboRefChangeHandler = this.onQboRefChangeHandler.bind(this);

    if(!props.headerRow){
      this.state = {
        qbo_ref: this.props.ticket.qbo_ref,
      }
    }
  }

  onQboRefChangeHandler(e){
    this.setState({
      qbo_ref: e.target.value,
    })
    this.props.onChangeHandler(this.props.ticket.store, this.props.ticket.order_id, e);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   debugger;
  //   if(nextState.qbo_ref !== this.state.qbo_ref) {
  //     debugger;
  //   }
  // }

  render(){
    if(this.props.headerRow) {
      return (
        <div className="non-standard-tickets-table-row headers">
          <div className="column store">
            Store
          </div>
          <div className="column bizdate">
            bizdate
          </div>
          <div className="column order-id">
            Order ID
          </div>
          <div className="column paytype">
            Paytype
          </div>
          <div className="column ccnum">
            ccnum
          </div>
          <div className="column pickup-name">
            Pickup Name
          </div>
          <div className="column full-name">
            Full Name
          </div>
          <div className="column company">
            Company
          </div>
          <div className="column taxexemption">
            Taxexemption
          </div>
          <div className="column order-total">
            Order Total
          </div>
          <div className="column qbo-ref">
            qbo_ref
          </div>
          <div className="column action">
            Action
          </div>
        </div>
      )
    } else {
      return (
        <div className="non-standard-tickets-table-row">
          <div className="column store">
            {this.props.store.stores_name}
          </div>
          <div className="column bizdate">
            {this.props.ticket.bizdate}
          </div>
          <div className="column order-id">
            {this.props.ticket.order_id}
          </div>
          <div className="column paytype">
            {this.props.ticket.paytype}
          </div>
          <div className="column ccnum">
            {this.props.ticket.ccnum}
          </div>
          <div className="column pickup-name">
            {this.props.ticket.pickup_name}
          </div>
          <div className="column full-name">
            {this.props.ticket.fname + " " + this.props.ticket.lname}
          </div>
          <div className="column company">
            {this.props.ticket.company}
          </div>
          <div className="column taxexemption">
            {this.props.ticket.taxexemption}
          </div>
          <div className="column order-total">
            {this.props.ticket.order_total}
          </div>
          <div className="column qbo-ref">
            {/* {this.props.ticket.qbo_ref} */}
            <input value={this.state.qbo_ref || ''} onChange={this.onQboRefChangeHandler}/>
          </div>
          <div className="column action">
            {this.props.ticket.action}
          </div>
        </div>
      )
    }
  }
}