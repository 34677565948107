import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Table } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./NewBiEntry.css";
import { API } from "aws-amplify";
import _ from 'lodash';

export default class DailyDashboard extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      data: null,
      dataLoaded: false,
      fromDate: '',
      groupBy: 'day',
      isLoading: null,
      kpiData: null,
      selectedStore: "3394-0002 North Platte",
      toDate: ''
    };
  }

  componentDidMount(){
    let kpiQuery = 'SELECT * FROM grow_prod.KPI';
    try {
      this.executeSingleSelect(kpiQuery, 0).then(
        kpiData => {
          this.setState({
            kpiData
          })
        }
      ).catch(error => console.log("Error fetching KPI config: ", error))
    } catch (e) {
      alert(e);
    }
  }

  validateForm() {
    return true;
    // return this.state.idApi > 0 && this.state.biDate !== '' && /^[12][09][0129][0-9]-[01][0-2]-[0-3][0-9].[0-2][0-9]:[0-5][0-9]$/.test(this.state.biDate) && this.validateFloat(this.state.biDebit) && this.validateFloat(this.state.biCredit) && this.validateFloat(this.state.biAmount) && this.validateStore(this.state.biExternalid);
  }

  validateStore(store) {
    return store === '3394-0001 Grand Island' || store === '3394-0002 North Platte' || store === '3394-0003 Kearney' || store === '3394-0004 Columbus' || store === '3394-0005 Norfolk';
  }

  validateFloat(n) {
    if (n === '') {
      return true;
    }
    // This won't allow any whole numbers. Is that on purpose?
    return Number(n) === parseFloat(n) && n % 1 !== 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ data: null });

    this.setState({ isLoading: true });
    let storeId = 0;
    switch (this.state.selectedStore) {
      case '3394-0001 Grand Island':
        storeId = 4;
        break;
      case '3394-0002 North Platte':
        storeId = 6;
        break;
      case '3394-0003 Kearney':
        storeId = 2;
        break;
      case '3394-0004 Columbus':
        storeId = 5;
        break;
      case '3394-0005 Norfolk':
        storeId = 3;
        break;
      default:
        break;
    }
    let query = `SELECT s1.stores_name, p1.week, p1.period, p1.quarter, p1.year, d1.bizdatesale, d1.netsales, d2.netsales as py_netsales, d1.tickets, d2.tickets as py_tickets, d1.actual_hours, d1.actual_charted_hours as charted_hours, d1.customer_complaint as complaints, d1.portal, d1.failed, d1.late, d1.breadattach, d1.HNR, d1.brokenpromise, d1.inventory_cheese_variance as Cheese, d1.inventory_box_variance, d1.over_under_calc, d1.over_under FROM grow_prod.daily_summary d1 LEFT JOIN grow_prod.Stores s1 ON d1.storeid = s1.idStores LEFT JOIN grow_prod.Periods p1 ON d1.bizdatesale = p1.date LEFT JOIN grow_prod.daily_summary d2 ON d1.lastyear = d2.bizdatesale AND d1.storeid = d2.storeid`;
    if (this.state.selectedStore == 'multi_store') {
      if (this.state.groupBy === 'day') {
        query += ` WHERE d1.bizdatesale = '${this.state.fromDate}'` 
        query += ` GROUP BY s1.stores_name`
      } else {
        query += ` WHERE p1.${this.state.groupBy} IN (SELECT p.${this.state.groupBy} FROM grow_prod.Periods p WHERE p.the_date >= '${this.state.fromDate}' AND p.the_date <= '${this.state.toDate}') AND d1.bizdatesale >= '${this.state.fromDate}' AND d1.bizdatesale <= '${this.state.toDate}'` 
        query += ` GROUP BY s1.stores_name`
      }
    } else {
      if (this.state.groupBy === 'day') {
        query += ` WHERE s1.Stores_qbo_Customer_id = ${storeId} AND d1.bizdatesale >= '${this.state.fromDate}' AND d1.bizdatesale <= '${this.state.toDate}'` 
        query += ` GROUP BY d1.bizdatesale`
      } else {
        query += ` WHERE s1.Stores_qbo_Customer_id = ${storeId} AND p1.${this.state.groupBy} IN (SELECT p.${this.state.groupBy} FROM grow_prod.Periods p WHERE p.the_date >= '${this.state.fromDate}' AND p.the_date <= '${this.state.toDate}') AND d1.bizdatesale >= '${this.state.fromDate}' AND d1.bizdatesale <= '${this.state.toDate}'` 
        query += ` GROUP BY p1.${this.state.groupBy}`
      }
    }
    try {
      let result = await this.executeSingleSelect(query, storeId);
      this.setState({ dataLoaded: true });
      this.setState({ isLoading: false });
      this.setState({ data: result });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  executeSingleSelect(query, storeId) {
    const dateTimeStamp = + new Date();
    // let payload = {"query": query}
    let payload = {"query": query, "store": storeId, "datestamp": dateTimeStamp};
    // for the select only api use "/single/untitled" instead of "/untitled"
    return API.post("mi6-bi", "/single/untitled", { 
      body: payload,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
      this.setState({ isLoading: false });
    });
  }

  hexToRgbA(hex, opacity){
    opacity = opacity || '1'
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + opacity + ')';
    }
    throw new Error('Bad Hex');
  }

  getPercentage(percentageChange, divisorBound, otherBound){
    if (!percentageChange && percentageChange !== 0){
      return 0;
    }
    if (divisorBound === otherBound){
      return 1;
    } else {
      return percentageChange/divisorBound;
    }
  }

  negativeGradientStyles(percentageChange, kpiData){
    if (kpiData['Type'] == 3) {
      percentageChange = Math.abs(percentageChange)
    }

    let color = null;
    try {
      if (percentageChange < kpiData['1_low']) {
        color = this.hexToRgbA(kpiData['1_color'], '1')

      } else if (percentageChange >= kpiData['1_low'] && percentageChange < kpiData['1_high']) {
        let percentage = 1 - (this.getPercentage(percentageChange, kpiData['1_high'], kpiData['1_low']))
        color = this.hexToRgbA(kpiData['1_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['1_high'] && percentageChange < kpiData['2_low']) {
        color = this.hexToRgbA(kpiData['2_color'], '1')

      } else if(percentageChange >= kpiData['2_low'] && percentageChange < kpiData['2_high']) {
        let percentage = 1 - (this.getPercentage(percentageChange, kpiData['2_high'], kpiData['2_low']))
        color = this.hexToRgbA(kpiData['2_color'], `${percentage}`)

      } else if(percentageChange >= kpiData['2_high'] && percentageChange < kpiData['3_low']) {
        color = this.hexToRgbA(kpiData['3_color'], '1')

      } else if (percentageChange >= kpiData['3_low'] && percentageChange < kpiData['3_high']) {
        let percentage = 1 - (this.getPercentage(percentageChange, kpiData['3_high'], kpiData['3_low']))
        color = this.hexToRgbA(kpiData['3_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['3_high']) {
        color = '#FFFFFF'

      } else {
        color = '#FFFFFF'
      }
    } catch(e) {
      color = '#FFFFFF'
    }

    let styles = {
      backgroundColor: color
    };

   return styles; 
  }

  positiveGradientStyles(percentageChange, kpiData){
    if (kpiData['Type'] == 3) {
      percentageChange = Math.abs(percentageChange)
    }

    let color = null;
    try {
      if (percentageChange < kpiData['1_low']) {
        color = '#FFFFFF'

      } else if (percentageChange >= kpiData['1_low'] && percentageChange < kpiData['1_high']) {
        let percentage = this.getPercentage(percentageChange, kpiData['1_high'], kpiData['1_low'])
        color = this.hexToRgbA(kpiData['1_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['1_high'] && percentageChange < kpiData['2_low']) {
        color = this.hexToRgbA(kpiData['1_color'], '1')

      } else if(percentageChange >= kpiData['2_low'] && percentageChange < kpiData['2_high']) {
        let percentage = this.getPercentage(percentageChange, kpiData['2_high'], kpiData['2_low'])
        color = this.hexToRgbA(kpiData['2_color'], `${percentage}`)

      } else if(percentageChange >= kpiData['2_high'] && percentageChange < kpiData['3_low']) {
        color = this.hexToRgbA(kpiData['2_color'], '1')

      } else if (percentageChange >= kpiData['3_low'] && percentageChange < kpiData['3_high']) {
        let percentage = this.getPercentage(percentageChange, kpiData['3_high'], kpiData['3_low'])
        color = this.hexToRgbA(kpiData['3_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['3_high']) {
        color = this.hexToRgbA(kpiData['3_color'], '1')

      } else {
        color = '#FFFFFF'
      }
    } catch(e) {
      color = '#FFFFFF'
    }

    let styles = {
      backgroundColor: color
    };

   return styles; 
  }

  centerGradientStyles(percentageChange, kpiData){
    if (kpiData['Type'] == 3) {
      percentageChange = Math.abs(percentageChange)
    }

    let color = null;
    try {
      if (percentageChange < kpiData['1_low']) {
        color = this.hexToRgbA(kpiData['1_color'], '1')

      } else if (percentageChange >= kpiData['1_low'] && percentageChange < kpiData['1_high']) {
        let percentage = 1 - (this.getPercentage(percentageChange, kpiData['1_high'], kpiData['1_low']))
        color = this.hexToRgbA(kpiData['1_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['1_high'] && percentageChange < kpiData['2_low']) {
        color = this.hexToRgbA(kpiData['2_color'], '1')

      } else if(percentageChange >= kpiData['2_low'] && percentageChange < kpiData['2_high']) {
        let percentage = this.getPercentage(percentageChange, kpiData['2_high'], kpiData['2_low'])
        color = this.hexToRgbA(kpiData['2_color'], `${percentage}`)

      } else if(percentageChange >= kpiData['2_high'] && percentageChange < kpiData['3_low']) {
        color = this.hexToRgbA(kpiData['2_color'], '1')

      } else if (percentageChange >= kpiData['3_low'] && percentageChange < kpiData['3_high']) {
        let percentage = this.getPercentage(percentageChange, kpiData['3_high'], kpiData['3_low'])
        color = this.hexToRgbA(kpiData['3_color'], `${percentage}`)

      } else if (percentageChange >= kpiData['3_high']) {
        color = this.hexToRgbA(kpiData['3_color'], '1')

      } else {
        color = '#FFFFFF'
      }
    } catch(e) {
      color = '#FFFFFF'
    }

    let styles = {
      backgroundColor: color
    };

   return styles; 
  }

  getStyles(value, kpiType) {
    const {kpiData: kpiDataState} = this.state;
    let kpiData = null;
    for (const [index, value] of kpiDataState.entries()) {
      if (value.name == kpiType) {
        kpiData = value
      }
    }

    let styles = {
      backgroundColor: '#FFFFFF'
    };

    if (kpiData['gradient'] == 0) {
      styles = this.positiveGradientStyles(value, kpiData)
    } else if(kpiData['gradient'] == 1) {
      styles = this.negativeGradientStyles(value, kpiData)
    } else if(kpiData['gradient'] == 2) {
      styles = this.centerGradientStyles(value, kpiData)
    } 

    return styles;
  }

  render() {
    const {dataLoaded, data, selectedStore, groupBy} = this.state;
    const items = []

    if (data) {
      for (const [index, value] of data.entries()) {
        let groupByValue = value.stores_name;
        if (selectedStore !== 'multi_store') {
          if (groupBy === 'day') {
            groupByValue = value['bizdatesale']
          } else {
            groupByValue = value[groupBy]
          }
        }
        let salesStyles = this.getStyles((value.netsales/value.py_netsales), 'Sales')
        let ticketStyles = this.getStyles((value.tickets/value.py_tickets), 'Tickets')
        let laborHoursStyles = this.getStyles((value.actual_hours/value.charted_hours), 'Labor Hours')
        let customerComplaintsStyles = this.getStyles(value.complaints, 'Customer Complaints')
        let portalOrdersStyles = this.getStyles(value.portal, 'Portal Orders')
        let portalInStyles = this.getStyles(((value.portal - value.failed)/value.portal), 'Portal % In')
        let portalOnTimeStyles = this.getStyles(((value.portal - value.failed - value.late)/(value.portal - value.failed)), 'Portal % On Time')
        let breadAttachStyles = this.getStyles((value.breadattach/value.tickets), 'Bread Attach')
        let brokenPromiseStyles = this.getStyles((value.brokenpromise/value.HNR), 'Broken Promise')
        let cheeseVarianceStyles = this.getStyles(value.Cheese, 'Cheese Variance')
        let boxVarianceStyles = this.getStyles(value.actual_hours/value.charted_hours, 'Box Variance')
        let cashStyles = this.getStyles((value.over_under_calc ? value.over_under_calc : value.over_under), 'Cash')

        items.push(<tr key={index}>
          <td>{groupByValue}</td>
          <td>${value.netsales}</td>
          <td style={salesStyles}>${(value.netsales - value.py_netsales).toFixed(2)}</td>
          <td style={ticketStyles}>{(value.tickets - value.py_tickets).toFixed(0)}</td>
          <td style={laborHoursStyles}>{(value.actual_hours - value.charted_hours).toFixed(2)}</td>
          <td>${(value.netsales/value.actual_hours).toFixed(2)}</td>
          <td style={customerComplaintsStyles}>{value.complaints}</td>
          <td style={portalOrdersStyles}>{value.portal}</td>
          <td style={portalInStyles}>{(((value.portal - value.failed)/value.portal) * 100).toFixed(2)}%</td>
          <td style={portalOnTimeStyles}>{(((value.portal - value.failed - value.late)/(value.portal - value.failed)) * 100).toFixed(2)}%</td>
          <td style={breadAttachStyles}>{((value.breadattach/value.tickets) * 100).toFixed(2)}%</td>
          <td>{value.HNR}</td>
          <td style={brokenPromiseStyles}>{((value.brokenpromise/value.HNR) * 100).toFixed(2)}%</td>
          <td style={cheeseVarianceStyles}>{value.Cheese}</td>
          <td style={boxVarianceStyles}>{value.inventory_box_variance}</td>
          <td style={cashStyles}>${value.over_under_calc ? value.over_under_calc : value.over_under}</td>
        </tr>)
      }
    }
  

    return (
      <div className="DailyDashboard">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="selectedStore" bsSize="large">
            <ControlLabel>Store(s)</ControlLabel>
            <FormControl autoFocus componentClass="select" onChange={this.handleChange}>
              <option value='3394-0001 Grand Island'>3394-0001 Grand Island</option>
              <option value='3394-0002 North Platte'>3394-0002 North Platte</option>
              <option value='3394-0003 Kearney'>3394-0003 Kearney</option>
              <option value='3394-0004 Columbus'>3394-0004 Columbus</option>
              <option value='3394-0005 Norfolk'>3394-0005 Norfolk</option>
              <option value='multi_store'>All Stores</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="groupBy" bsSize="large">
            <ControlLabel>Group By</ControlLabel>
            <FormControl autoFocus componentClass="select" onChange={this.handleChange}>
              <option value='day'>Day</option>
              <option value='week'>Week</option>
              <option value='period'>Period</option>
              <option value='quarter'>Quarter</option>
            </FormControl>
          </FormGroup>
          <FormGroup controlId="fromDate" bsSize="large">
            <ControlLabel>From</ControlLabel>
            <FormControl autoFocus type="date" onChange={this.handleChange}/>
          </FormGroup>
          <FormGroup controlId="toDate" bsSize="large">
            <ControlLabel>To</ControlLabel>
            <FormControl autoFocus type="date" onChange={this.handleChange}/>
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Enter"
            loadingText="Sending Data…"
          />
        </form>
        {dataLoaded &&
          <Table striped bordered hover variant="dark" size="sm">
          <thead>
            <tr>
              <th>{selectedStore === 'multi_store' ? groupBy : 'Store'}</th>
              <th>Sales</th>
              <th>Sales +/-</th>
              <th>Ticket +/-</th>
              <th>Labor Hours +/-</th>
              <th>$ per LH Actual</th>
              <th>Customer Complaint</th>
              <th>Portal Orders</th>
              <th>Portal % in</th>
              <th>Portal % On Time</th>
              <th>Bread Attach</th>
              <th>HNR</th>
              <th>Broken Promise</th>
              <th>Cheese Variance</th>
              <th>Box Variance</th>
              <th>Cash +/-</th>
            </tr>
          </thead>
          <tbody>
            {items}
          </tbody>
        </Table>
        }
      </div>
    );
  }
}
