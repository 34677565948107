import React, { Component } from 'react';

import TriangleIcon from '../icons/TriangleIcon';

import '../../css/components/non-standard-tickets-table-headers.scss'

const headerOrder = [
  {
    id: 'store',
    text: 'Store',
  },
  {
    id: 'bizdate',
    text: 'Date',
  },
  {
    id: 'order_id',
    text: 'Order ID',
  },
  {
    id: 'paytype',
    text: 'Pay Type',
  },
  {
    id: 'ccnum',
    text: 'ccnum',
  },
  {
    id: 'pickup_name',
    text: 'Pickup Name',
  },
  {
    id: 'full-name',
    text: 'Full Name',
  },
  {
    id: 'company',
    text: 'Company',
  },
  {
    id: 'taxexemption',
    text: 'Tax Exemption',
  },
  {
    id: 'order_total',
    text: 'Order Total',
  },
  {
    id: 'qbo_ref',
    text: 'qbo_ref',
  },
  {
    id: 'action',
    text: 'Action',
  },
]
export default class NonStandardTicketsTableHeaders extends Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  onClickHandler(column) {
    this.props.headerOnClickHandler(column);
  }

  render() {
    const {
      column,
      direction,
    } = this.props.sortOptions;

    const headers = headerOrder.map(header => {
      const {
        id,
        text,
      } = header;

      return (
        <div key={ id } className={ `column ${ id === column ? 'column--sort-option':'' }` } onClick={ this.onClickHandler.bind(this, id) } >
          { text }
          <div className="sort-icons-wrapper">
            <TriangleIcon className={ `${ direction === 'asc' ? '' : 'hide-icon' }` } />
            <TriangleIcon className={ `${ direction === 'desc' ? '' : 'hide-icon' }` } direction="down" />
          </div>
        </div>
      )
    })

    return (
      <div className="non-standard-tickets-table-headers non-standard-tickets-table-row">
        { headers }
      </div>
    )
  }
}