import React, { Component } from 'react';
import "../../css/components/modal.scss";


class Modal extends Component {
  render() {
    return (
      <div className="modal">
        <div className="modal__mask" onClick={this.props.close}></div>
        <div className={`modal__custom-modal-content ${this.props.className ? this.props.className : ''}`}>
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default Modal;
