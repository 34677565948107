import BIQueries from '../queries/BIQueries';

import QueryHelpers from '../helpers/QueryHelpers';

const DailyBIActions = {
  calculateActualSales: function(params) {
    const query = `SELECT storeid, date, SUM(nettransactions) FROM grow_prod.ordersummary WHERE date="${ params.date }" AND storeid=${ params.storeId } AND accountnum NOT IN(1900,990,991,992,993) GROUP BY storeid, date`;

    return QueryHelpers.runSelectAPI(query)
  },
}


export default DailyBIActions;
