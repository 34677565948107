import PeriodsQueries from '../queries/PeriodsQueries';
import BIQueries from '../queries/BIQueries';
import APIQueries from '../queries/APIQueries';
import OrderSummaryQueries from '../queries/OrderSummaryQueries';
import { formatDate, cloneArray } from '../helpers/GeneralHelpers';

const apiQtyVsAmount = {
  'Projected Sales': 'BI_amount',
  'Scheduled Hours': 'BI_qty',
  'Charted Hours': 'BI_qty',
  'Charted $/LH': 'BI_amount',
  'Projected Oven Count': 'BI_qty',
}

const ScheduleInputHelpers = {
  findEarliestSummary: function(salesSummaries){
    if(salesSummaries.length == 0) return null;
    var earliestSummary = salesSummaries[0];
    for(var i = 1; i < salesSummaries.length ; i++){
        var currentSummary = salesSummaries[i];
        if(new Date(currentSummary.bizdatesale) < new Date(earliestSummary.bizdatesale)){
            earliestSummary = currentSummary;
        }
    }

    return earliestSummary;
  },
  getPreviousSales: function(storeId, date){
    return this.getPastDates(date).then(result => {
      return OrderSummaryQueries.find({ bizdatesale: result, storeid: storeId }, ['netsales', 'bizdatesale', 'storeid']).then(answer => {
        const lastYearsSales = this.findEarliestSummary(answer).netsales;
        let average = 0;
        let count = 0;
        if(answer){
          for(let i = 0; i < answer.length; i++){
            if (answer[i].netsales) {
              average = average + parseFloat(answer[i].netsales);
              count++;
            }
          }
  
          average = average / count;
        }
        
        return {
          sysSalesProjected: average.toFixed(2),
          lastYearsSales: lastYearsSales,
        };
      });
    });
  },
  // This is where you are having problems in Safari
  getPastDates: function(date){
    return PeriodsQueries.find({date: date}).then(periods => {
      // let dates = [periods[0].date];
      let dates = [];
      // debugger;

      let oneWeekBack = new Date(periods[0].date + " 00:00");
      for (let i = 0; i < 6; i++) {
        oneWeekBack.setDate(oneWeekBack.getDate() - 7);
        let newDate = formatDate(oneWeekBack);
        dates.push(newDate);
      }

      return PeriodsQueries.find({year: periods[0].year - 1, day: periods[0].day}).then(oneYearAgo => {
        if(oneYearAgo.length > 0){
          dates.push(oneYearAgo[0].date)
        }
        return dates;
      })
      
    })

  },
  formatDataForCreate: function(originalData){
    // Remove rows that haven't been edited.
    let data = cloneArray(originalData);
    let formatedData = [];
    
    // Objects that have less then 3 attributes haven't been edited yet
    // The three initial attributes are storeName, date, and sys projected sales.
    // This doesn't account for the rows that already have information in the database 
    let filterdArray = data.filter(element => Object.keys(element).length > 3);

    let arrayLengths = filterdArray.map(function(a){return Object.keys(a).length;});
    let largestObjectIndex = arrayLengths.indexOf(Math.max.apply(Math, arrayLengths));

    let idAPIs = {};

    let apiNames = Object.keys(filterdArray[largestObjectIndex]);
    return APIQueries.find({API_Name: apiNames}).then(results => {
      results.forEach(element => {
        idAPIs[element.API_Name] = element.idAPI;
      })

      return idAPIs;
    }).then(() => {

      filterdArray.forEach(element => {
        let storeName = element.storeName;
        let date = element.date;
        let dataObject = { storeName: element.storeName, date: element.date, data:[]};
      
        for(let key in element){
          if(Object.keys(idAPIs).indexOf(key) !== -1){
            if(element[key]){
              let tempObject = {
                BI_externalid: storeName,
                BI_Date: date,
                idAPI: idAPIs[key],
                BI_fieldname: key,
                // apiQtyVsAmount[key]: element[key],
              }
  
              tempObject[apiQtyVsAmount[key]] = element[key];
  
              dataObject.data.push(tempObject);
            }
          }
        }
        formatedData.push(dataObject);
      })
      return formatedData;
    })
  },
}


export default ScheduleInputHelpers;


// Select Date(BI_Date) as Date, BI_externalid as store, sum(BI_amount) as Sales
// from grow_prod.BI
// WHERE ((idAPI =28 AND BI_externalid = '3394-0001 Grand Island') AND (Date(BI_Date) = '2017-03-13' OR Date(BI_Date) = '2017-03-20' OR Date(BI_Date) = '2017-03-27'))
// Group by BI_externalid, Date(BI_Date);