import QueryHelpers from "../helpers/QueryHelpers";
import Queries from "./Queries";

const columns = ["Employees_CVid", "Employees_payrollID", "Employees_firstname", "Employees_lastname"];

const EmployeesQueries = {
  find: function(params){
    var query = Queries.find("Employees", params, columns);

    return QueryHelpers.runSelectAPI(query);
  },
}

export default EmployeesQueries;