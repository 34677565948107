import React from 'react';

import Modal from '../../components/Modal';

import { WaitingForResponse } from './modal-content/waiting-for-response';
import { SkyviaCheck } from './modal-content/skyvia-check';
import { MarkCompleteFinished } from './modal-content/mark-complete-finished';
import { TooManyRecords } from './modal-content/too-many-records';

import './action-response-modal.scss';
export function ActionResponseModal(props) {
  const {
    closeModal,
    modalState,
    recordCount,
    updateState,
  } = props;

  let modalContent;
  switch (modalState) {
    case 'loading':
      modalContent = <WaitingForResponse />;
      break;

    case 'skyvia-check':
      modalContent = <SkyviaCheck recordCount={ recordCount } updateState={ updateState } />;
      break;

    case 'mark-complete-finished':
      modalContent = <MarkCompleteFinished recordCount={ recordCount } updateState={ updateState } />;
      break;

    case 'too-many-records':
      modalContent = <TooManyRecords recordCount={ recordCount } />;
      break;
  
    default:
      break;
  }

  return (
    <Modal close={ closeModal }>
      <div className="action-response-modal">
        { modalContent }
      </div>
    </Modal>
  )
}