import _ from 'lodash';
import sha256 from 'crypto-js/sha256';

import SettingVariables from "../../SettingVariables";

import QueryHelpers from "../helpers/QueryHelpers";

import Queries from './Queries';

const columns = ["idBI", "idAPI", "BI_externalid", "BI_externalid2", "BI_externalid3", "BI_externalid4", "BI_Date", "BI_fieldname", "BI_debit", "BI_credit", "BI_amount", "BI_qty"];

const BIQueries = {
  create: function(params){
    // This function expects an array of objects holding the information to be added to the database.

    // if params isn't an array make an array of length 1 using that given object
    if (!Array.isArray(params)) params = [params];
    
    let queries = [];
    let store = params[0].BI_externalid;

    params.forEach(param => {
      const includeBiExternalid = param.BI_externalid;
      const includeBiExternalid2 = param.BI_externalid2;
      const includeBiExternalid3 = param.BI_externalid3;
      const includeBiExternalid4 = param.BI_externalid4;
      const includeBiFieldname = param.BI_fieldname;
      const includeBiDebit = param.BI_debit;
      const includeBiCredit = param.BI_credit;
      const includeBiAmount = param.BI_amount;
      const includeBiQty = param.BI_qty;
  
      const idAPI = SettingVariables.mode === 'development' ? param.idAPI + 100 : param.idAPI;
  
      // const store = param.BI_externalid;
      let query = "INSERT INTO `BI` (`idBI`, `idAPI`, `BI_Date`";
      let biMsg = _.clone(idAPI);
      let values = "', " + idAPI + ", '" + param.BI_Date + ":00'";
      let duplicateKey = " ON DUPLICATE KEY UPDATE"
      if (includeBiExternalid) {
        query = query + ",`BI_externalid`";
        biMsg = biMsg + " " + param.BI_externalid;
        values = values + ", '" + param.BI_externalid + "'";
      }
      if (includeBiExternalid2) {
        query = query + ",`BI_externalid2`";
        biMsg = biMsg + " " + param.BI_externalid2;
        values = values + ", '" + param.BI_externalid2 + "'";
      }
      if (includeBiExternalid3) {
        query = query + ",`BI_externalid3`";
        biMsg = biMsg + " " + param.BI_externalid3;
        values = values + ", '" + param.BI_externalid3 + "'";
      }
      if (includeBiExternalid4) {
        query = query + ",`BI_externalid4`";
        biMsg = biMsg + " " + param.BI_externalid4;
        values = values + ", '" + param.BI_externalid4 + "'";
      }
      if (includeBiFieldname) {
        query = query + ",`BI_fieldname`";
        biMsg = biMsg + " " + param.BI_fieldname;
        values = values + ", '" + param.BI_fieldname + "'";
      }
      if (includeBiDebit) {
        query = query + ",`BI_debit`";
        values = values + ", " + param.BI_debit;
        duplicateKey = duplicateKey + " BI_debit = VALUES(BI_debit),";
      }
      if (includeBiCredit) {
        query = query + ",`BI_credit`";
        values = values + ", " + param.BI_credit;
        duplicateKey = duplicateKey + " BI_credit = VALUES(BI_credit),";
      }
      if (includeBiAmount || includeBiAmount === 0) {
        query = query + ",`BI_amount`";
        values = values + ", " + param.BI_amount;
        duplicateKey = duplicateKey + " BI_amount = VALUES(BI_amount),";
      }
      if (includeBiQty || includeBiQty === 0) {
        query = query + ",`BI_qty`";
        values = values + ", " + param.BI_qty;
        duplicateKey = duplicateKey + " BI_qty = VALUES(BI_qty),";
      }

      // Add BI_Debug column to the query. This helps identify what records came from the app and what came from other places.
      query = query + ",`BI_Debug`";
      values = values + ", " + true;
      // duplicateKey = duplicateKey + " BI_qty = VALUES(BI_qty),";

      biMsg = biMsg + " " + param.BI_Date + ":00";
      biMsg = sha256(biMsg);
      query = query + ") VALUES ('" + biMsg + values + ")";
      
      if (duplicateKey !== " ON DUPLICATE KEY UPDATE" && duplicateKey.substr(duplicateKey.length - 1) === ",") {
        query = query + duplicateKey.substr(0,duplicateKey.length - 1);
      }

      queries.push(query);
    })

    return QueryHelpers.runInsertAPI(queries, store);
    
  },
  find: function(params){
    
    const query = Queries.find("BI", params, columns);

    return QueryHelpers.runSelectAPI(query);
  },

  findPastSalesForProjectedSales: function(storeId, dates){
    // let query = 
    // `SELECT Date(BI_Date) AS date, BI_externalid AS store, SUM(BI_amount) AS sales 
    // FROM BI 
    // WHERE ((idAPI = 28 AND BI_externalid = '${store}') AND (`

    // for(let i = 0; i < dates.length; i++){
    //   query = query + `Date(BI_Date) = '${dates[i]}'`
    //   if(i !== dates.length - 1) query = query + ' OR ';
    // }
    // query = query + ')) GROUP BY BI_externalid, Date(BI_Date);';

    let query = `SELECT storeid, ordersummary.date, sum(nettransactions) as sales

    FROM grow_prod.ordersummary
    
    WHERE accountnum NOT IN (10, 1900, 990, 991, 992, 993) AND storeid = ${ storeId } AND ordersummary.date IN (`

    for (let i = 0; i < dates.length; i ++) {
      query = query + '\'' + dates[i] + '\'';
      if (i < dates.length - 1) {
        query = query + ', ';
      }
    }
    
    query = query + `) GROUP BY storeid, ordersummary.date
    
    ORDER BY date, storeid;`

    debugger;

    return QueryHelpers.runSelectAPI(query);
  }
}

export default BIQueries;