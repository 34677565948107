import React, { Component } from "react";


// Props 
//  -- strokeColor - must be a string
//  -- direction up, down, left or right. Default is right.


export default class ChevronIcon extends Component {
  render(){
    var strokeColor = this.props.strokeColor ? this.props.strokeColor : "#141414";
    var points;

    // I didnt' want to deal with rotating it so instead I did it ghetto like this.
    switch(this.props.direction){
      case "up":
        points = "0 10 5 5 10 10";
        break;
      case "down":
        points = "0 0 5 5 10 0";
        break;
      case "left":
        points = "10 0 5 5 10 10"
        break;
      default:
        points = "0 0 5 5 0 10";
        break;
    }

    return (
      <svg width="10px" height="10px" viewBox="0 0 10 10">
        <polyline stroke={strokeColor} fill="none" strokeWidth="2" points={points}/>
      </svg>
    )
  }
}