import QueryHelpers from "../helpers/QueryHelpers";

import Queries from "./Queries";

const columns = ["idStores", "stores_name", "Stores_BL_CustomerNum", "Stores_BLRefID", "Stores_APAccountRefId", "Stores_qbo_classrefid"]

const StoreQueries = {
  find: function(params){
    var query = Queries.find("Stores", params, columns);

    // console.log(query);
    return QueryHelpers.runSelectAPI(query);
  },
}

export default StoreQueries;
