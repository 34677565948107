import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import NonStandardTicketManagement from "../components/NonStandardTicketManagement";

export default class NonStandardTicket extends Component {
  render(){
    return (
      <div>
        <Switch>
          <Route component={NonStandardTicketManagement}/>
        </Switch>
      </div>
    )
  }
}