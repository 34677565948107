import React, { Component } from "react";

import './schedule-input-table-row.scss';

import SettingVariables from "../../SettingVariables";

import ScheduleInputHelpers from "../helpers/ScheduleInputHelpers";
import { getLaborChartRecord } from "../helpers/LaborChartHelpers";

import LoadingPuffIcon from "../icons/LoadingPuffIcon";
import BIQueries from "../queries/BIQueries";

export default class ScheduleInputTableRow extends Component {

  constructor(props){
    super(props);

    this.projectedSalesChangeHandler = this.projectedSalesChangeHandler.bind(this);
    this.inputOnChangeHandler = this.inputOnChangeHandler.bind(this);

    this.state = {
      loading: true,
      dates: [],
      lastYearSales: '',
      sysSalesProjected: '',
      projectedSales: '',
      chartedHours: '',
      charter: '',
      scheduledHours: '',
    }

    ScheduleInputHelpers.getPreviousSales(props.store.idStores, props.date).then(result => {
      const updatedInfo = { 
        sysSalesProjected: result.sysSalesProjected,
        lastYearSales: result.lastYearsSales
      };
      props.updateRow(props.storeName, props.date, updatedInfo);

      this.setState({
        sysSalesProjected: result.sysSalesProjected,
        lastYearSales: result.lastYearsSales,
        loading: false,
      })
    });

    // This checks the input columns to see if that data already exists.
    
    const idAPIArray = [SettingVariables.idAPIConverter(43), SettingVariables.idAPIConverter(44), SettingVariables.idAPIConverter(45), SettingVariables.idAPIConverter(46)];

    BIQueries.find({BI_externalid: props.storeName, BI_Date: props.date, idAPI: idAPIArray}).then(result => {
      if(result && result.length > 0) {

        var updateInfo = {};
        result.forEach(r => {
          updateInfo[r.BI_fieldname] =  r.BI_amount || r.BI_qty;
        })
        
        this.props.updateRow(this.props.storeName, this.props.date, updateInfo);
      }
    })
    
    // For testing
    // BIQueries.find({BI_externalid: props.storeName, BI_Date: props.date, idAPI: [143, 144, 145, 146]}).then(result => {
    //   console.log(result);
    //   if(result && result.length > 0) {

    //     var updateInfo = {};
    //     result.forEach(r => {
    //       updateInfo[r.BI_fieldname] =  r.BI_amount || r.BI_qty;
    //     })
        
    //     this.props.updateRow(this.props.storeName, this.props.date, updateInfo);
    //   }
    // })
  }

  projectedSalesChangeHandler(e){
    let laborChartRecord = getLaborChartRecord(e.target.value);

    var updateInfo = {
      "Projected Sales": e.target.value,
      "Charted Hours": laborChartRecord ? laborChartRecord.Hours : '',
      "Charted $/LH": laborChartRecord ? laborChartRecord.$LH.toFixed(2) : '',
    }

    this.props.updateRow(this.props.storeName, this.props.date, updateInfo)
    
    this.setState({
      "Projected Sales": e.target.value,
      "Charted Hours": laborChartRecord ? laborChartRecord.Hours : '',
      "Charted $/LH": laborChartRecord ? laborChartRecord.$LH.toFixed(2) : '',
    })
  }

  inputOnChangeHandler(e){
    this.setState({
      [e.target.id]: e.target.value,
    })

    var updateInfo = {}
    updateInfo[e.target.id] = e.target.value;

    this.props.updateRow(this.props.storeName, this.props.date, updateInfo)
  }

  render(){
    var date = new Date(this.props.date + " 00:00");

    if(this.state.loading){
      return (
        <div className="schedule-input-table-row loading">
          <LoadingPuffIcon hexColor="#0077D7"/>
        </div>
      )
    } else {
      return (
        <div className="schedule-input-table-row">
          <div className="schedule-input-table-row__date">
            {date.toLocaleDateString()}
          </div>
          <div className="schedule-input-table-row__sys-sales-projected">
            {this.props.tableRow.sysSalesProjected || ''}
          </div>
          <div className="schedule-input-table-row__last-year-sales">
            {this.props.tableRow.lastYearSales || ''}
          </div>
          <div className="schedule-input-table-row__projected-sales">
            <input type="number" id="Projected Sales" value={this.props.tableRow["Projected Sales"] || ''} onChange={this.projectedSalesChangeHandler}/>
          </div>
          <div className="schedule-input-table-row__charted-hours">
            {this.props.tableRow["Charted Hours"] || ''}
          </div>
          <div className="schedule-input-table-row__charter">
            {this.props.tableRow["Charted $/LH"] || ''}
          </div>
          <div className="schedule-input-table-row__scheduled-hours">
            <input type="number" id="Scheduled Hours" value={this.props.tableRow['Scheduled Hours'] || ''} onChange={this.inputOnChangeHandler}/>
          </div>
        </div>
      )
    }
    
  }
}