import React, { Component } from "react";
import ReactAutocomplete from "react-autocomplete";
import Calendar from "react-calendar";

import EMPLOYEES from "../../data/EMPLOYEES";

import { formatDate, formatMS } from "../helpers/GeneralHelpers";

import EmployeesQueries from "../queries/EmployeesQueries";
import StoreQueries from "../queries/StoreQueries";
import SkillsQueries from "../queries/SkillsQueries";
import BIQueries from "../queries/BIQueries";

import Stopwatch from "./Stopwatch";

import "../../css/components/productivity-create-record.scss";

// Keys
// Store: BI_externalid
// Date/Time: BI_Date
// Skill: BI_externalid2
// Employee: BI_externalid3
// # of People: BI_externalid4
// Production Qty: BI_amount
// Time: BI_qty

const menuStyle={
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%',
  top: 'intial',
  left: 'initial',
}



export default class ProductivityCreateRecord extends Component {
  constructor(props){
    super(props);

    this.saveRecord = this.saveRecord.bind(this);
    this.updateState = this.updateState.bind(this);

    this.state = {
      value: '',
      record: {
        BI_Date: '',
        BI_externalid: '',
        BI_externalid2: '',
        BI_externalid3: '',
        BI_externalid4: '',
        BI_amount: '',
        BI_qty: 0,
      },
      employees: [],
      skills: [],
      stores: [],
    }

    EmployeesQueries.find().then(employees => {
      if(!employees){
        this.setState({
          employees: EMPLOYEES,
        })
      }
    })

    SkillsQueries.find().then(skills => {
      this.setState({
        skills: skills,
      })
    })

    StoreQueries.find().then(stores => {
      this.setState({
        stores: stores,
      })
    })
  }

  saveRecord(){
    const params = Object.assign(this.state.record, {idAPI: 53});
    BIQueries.create(params).then(result => {
      if (result) {
        const emptyRecord =  {
          BI_Date: '',
          BI_externalid: '',
          BI_externalid2: '',
          BI_externalid3: '',
          BI_externalid4: '',
          BI_amount: '',
          BI_qty: 0,
        }
        this.setState({
          record: emptyRecord,
        })
        this.props.close();
      }
    })
    
  }

  updateState(updateInfo){
    this.setState(updateInfo);
  }

  render() {

    const autocompleteStoresList = this.state.stores.map(store =>  {
      if(store.stores_name) {
        return {id: store.idStores, label: store.stores_name}
      }
    }).filter(store => store);

    const autoCompleteEmployeesList = this.state.employees.map((employee, index) =>  {
      return {id: employee.Employees_CVid, label: employee.Employees_firstname + ' ' + employee.Employees_lastname}
    });

    const autoCompleteSkillsList = this.state.skills.map(skill => {
      return {id: skill.idSkills, label: skill.skill}
    })

    const recordRows = [
      {
        inputType: "autocomplete",
        label: "Store",
        databaseColumn: "BI_externalid",
        options: autocompleteStoresList,
      },
      {
        inputType: "calendar",
        label: "Date/Time",
        databaseColumn: "BI_Date"
      },
      {
        inputType: "autocomplete",
        label: "Skill",
        databaseColumn: "BI_externalid2",
        options: autoCompleteSkillsList,
      },
      {
        inputType: "autocomplete",
        label: "Employee",
        databaseColumn: "BI_externalid3",
        options: autoCompleteEmployeesList,
      },
      {
        inputType: "input",
        label: "Production Qty",
        databaseColumn: "BI_amount"
      },
      {
        inputType: "stopwatch",
        label: "Time",
        databaseColumn: "BI_qty"
      },
    ]

    const rows = recordRows.map((field, index) => {
      switch(field.inputType){
        case "autocomplete":
          return (
            <AutoCompleteInputRow key={index} record={this.state.record} updateState={this.updateState} items={field.options} label={field.label} value={this.state.record[field.databaseColumn]} databaseColumn={field.databaseColumn} />
          )
        
        case "calendar": 
          return (
            <CalendarInputRow key={index} record={this.state.record} updateState={this.updateState} label={field.label} value={this.state.record[field.databaseColumn]} databaseColumn={field.databaseColumn}/>
          )

        case "stopwatch":
          return (
            <StopwatchInputRow key={index} record={this.state.record} updateState={this.updateState} label={field.label} value={this.state.record[field.databaseColumn]} databaseColumn={field.databaseColumn}/>
          )

        default: 
          return (
            <div key={index} >Nothing Cool Here</div>
          )
      }
    })

    if(this.state.stores.length === 0) return null;
    if(this.state.skills.length === 0) return null;
    return (
      <div className="productivity-create-record">
        <div className="productivity-create-record__page-title">Create Record</div>

        <div className="productivity-create-record__input-fields">
          {rows}
        </div>
        
        <div className="button" onClick={this.saveRecord}>Save Record</div>
      </div>
    );
  }
}

class AutoCompleteInputRow extends React.Component {
  onSelectHandler(value){
    const tempRecord = Object.assign({}, this.props.record);
    tempRecord[this.props.databaseColumn] = value;
    this.props.updateState({
      record: tempRecord,
    })
  }

  onChangeHandler(e){
    const tempRecord = Object.assign({}, this.props.record);
    tempRecord[this.props.databaseColumn] = e.target.value;
    this.props.updateState({
      record: tempRecord,
    })
  }
  render(){

    
    return (
      <div className="input-fields__row">
        <div className="row__label">
          {this.props.label}
        </div>
        <ReactAutocomplete
          items={this.props.items}
          shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
          getItemValue={item => item.label}
          renderItem={(item, highlighted) =>
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? '#eee' : 'transparent'}}
            >
              {item.label}
            </div>
          }
          value={this.props.value}
          onChange={e => this.onChangeHandler(e)}
          onSelect={value => this.onSelectHandler(value)}
          menuStyle={this.props.menuStyle || menuStyle}
        />
      </div>
    )
  }
}


class CalendarInputRow extends React.Component {
  constructor(props){
    super(props);

    this.onDateSelect = this.onDateSelect.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }
  onDateSelect(date){
    const newDate = formatDate(date);
    const tempRecord = Object.assign({}, this.props.record);
    tempRecord[this.props.databaseColumn] = newDate;
    this.props.updateState({
      record: tempRecord,
    })
  }

  onChangeHandler(e){
    const tempRecord = Object.assign({}, this.props.record);
    tempRecord[this.props.databaseColumn] = e.target.value;
    this.props.updateState({
      record: tempRecord,
    })
  }

  render(){
    return (
      <div className="input-fields__row">
        <div className="row__label">
          {this.props.label}
        </div>
        <input value={this.props.value} onChange={this.onChangeHandler}/>
        <Calendar 
          value={new Date()}
          onChange={this.onDateSelect} 
          calendarType="US"
        />
      </div>
    )
  }
}


class StopwatchInputRow extends React.Component {
  constructor(props){
    super(props);

    this.useTime = this.useTime.bind(this);
  }

  onChangeHandler(){
    console.log('hello new time');
  }

  useTime(ms){
    const tempRecord = Object.assign({}, this.props.record);
    tempRecord[this.props.databaseColumn] = ms;
    this.props.updateState({
      record: tempRecord,
    })
  }

  render(){
    return (
      <div className="input-fields__row">
        <div className="row__label">
          {this.props.label}
        </div>
        <input id="stopwatch-id" value={formatMS(this.props.value)} onChange={this.onChangeHandler}/>
        <Stopwatch useTime={this.useTime}/>
      </div>
    )
  }
}
