import React, { Component } from "react";


// Props 
//  -- strokeColor - must be a string
//  -- direction up, down, left or right. Default is right.

export default class PlusIcon extends Component {
  render(){
    return (
      <svg className="plus-icon-svg" width={this.props.width || "12px"} height={this.props.width || "12px"} viewBox="0 0 46 46" strokeWidth={this.props.strokeWidth ? `${this.props.strokeWidth}px` : "5px"} stroke={this.props.color ? `${this.props.color}`:'black'}>
        <path d="M23,0 L23,46 M0,23 L46,23"></path>
      </svg>
    )
  }
}