import React, { Component } from "react";

import "../../css/components/non-standard-ticket-management.scss";

import StoreQueries from "../queries/StoreQueries";
import NonstandardTicketsQueries from "../queries/NonstandardTicketsQueries";

import NonStandardTicketsTable from "./NonStandardTicketsTable";
import OptionPill from "./OptionPill";
import Modal from "./Modal";
import CalendarDatePicker from "./CalendarDatePicker";
import SearchInput from "./SearchInput";

import { formatDate, arrayIncludes, cloneArray } from "../helpers/GeneralHelpers";
import { formatDataForFind, sortData } from "../helpers/NonStandardTicketHelpers";

import CalendarIcon from "../icons/CalendarIcon";
import LoadingPuffIcon from "../icons/LoadingPuffIcon";

const statusOptionsArray = [
  "Needs Action",
  "Complete"
]

export default class NonStandardTicketManagement extends Component {
  constructor(props){
    super(props);

    this.saveCrap = this.saveCrap.bind(this);
    this.searchForTickets = this.searchForTickets.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.onEnterHandler = this.onEnterHandler.bind(this);
    this.filterTickets = this.filterTickets.bind(this);
    this.headerOnClickHandler = this.headerOnClickHandler.bind(this);

    this.state = {
      processing: false,
      loading: true,
      storeOptions: [],
      statusOptions: statusOptionsArray,
      selectedStores: [],
      selectedStatuses: [statusOptionsArray[0]],
      tickets: [],
      visibleTickets: [],
      showStartCalendar: false,
      showEndCalendar: false,
      startDate: "",
      endDate: "",
      searchTerms: [],
      rowChanges: [],
      sortOptions: {
        column: 'store',
        direction: 'desc',
      }
    }
  }

  componentDidMount(){
    StoreQueries.find().then(stores => {
      this.setState({
        storeOptions: stores,
        selectedStores: stores,
      }, () => this.searchForTickets());
    })
  }

  searchForTickets(){ 
    const params = formatDataForFind(this.state.selectedStores, this.state.selectedStatuses, this.state.startDate, this.state.endDate);

    if (params.Action.length === 0 || params.store.length === 0) {
      this.setState({
        tickets: [],
        loading: false,
        processing: false,
      }, () => {
        this.filterTickets();
      })
    } else {
      NonstandardTicketsQueries.find(params).then(tickets => {
        console.log("tickets: ", tickets);
        this.setState({
          tickets: tickets,
          loading: false,
          processing: false,
        }, () => {
          this.filterTickets();
        })
      })
    }
  }

  saveCrap(){
    this.setState({
      processing: true,
    })
    this.state.rowChanges.forEach(rowChange => {
      NonstandardTicketsQueries.update(rowChange).then(result => {
        this.searchForTickets();
      })
    })
  }

  rowChangesChangeHandler = (store, orderId, e) => {
    let newRowChanges = cloneArray(this.state.rowChanges);
    const orderIds = this.state.rowChanges.map(change => change.order_id);
    const index = orderIds.indexOf(orderId);

    if(index >= 0){
      if(e.target.value === "") {
        newRowChanges[index].qbo_ref = "''";
        newRowChanges[index].action = "'Needs Action'";
      } else {
        newRowChanges[index].qbo_ref = `'${e.target.value}'`;
        newRowChanges[index].action = "'Complete'";
      }
    } else {
      if(e.target.value === ""){
        newRowChanges.push({ store: store, order_id: orderId, qbo_ref: "''", action: "'Needs Action'" });  
      } else {
        newRowChanges.push({ store: store, order_id: orderId, qbo_ref: `'${e.target.value}'`, action: "'Complete'" });
      }
    }

    this.setState({
      rowChanges: newRowChanges,
    })
  }

  toggleCalendar(stateName){
    this.setState({
      [stateName]: !this.state[stateName],
    })
  }

  onDateSelect(stateName, date){
    if(date === this.state[stateName]){
      return null;
    }
    const newDate = formatDate(date);

    this.setState({
      [stateName]: newDate,
      showEndCalendar: false,
      showStartCalendar: false,
    }, () => {
      this.searchForTickets();
    })
  }

  updateState(stateName, newValue){
    this.setState({
      [stateName]: newValue,
      loading: true,
    }, () => {
      this.searchForTickets();
    })
  }

  onEnterHandler(term){
    var searchTerms = cloneArray(this.state.searchTerms);

    searchTerms.push(term);

    this.setState({
      searchTerms: searchTerms,
    }, () => {
      this.filterTickets();
    })
  }

  termOnClickHandler(term){
    var searchTerms = cloneArray(this.state.searchTerms);
    var index = searchTerms.indexOf(term);

    searchTerms.splice(index, 1);

    this.setState({
      searchTerms: searchTerms,
    }, () => {
      this.filterTickets();
    })
  }

  filterTickets(){
    console.log('filterTickets');
    let visibleTickets = cloneArray(this.state.tickets);

    if (this.state.searchTerms.length === 0) {
      // this.setState({
      //   visibleTickets: visibleTickets,
      // })
    } else {
      for(let i = 0; i < this.state.tickets.length; i++){
        const testString = JSON.stringify(this.state.tickets[i]).toLowerCase();

        for(let j = 0; j < this.state.searchTerms.length; j++){
          const term = this.state.searchTerms[j].toLowerCase();

          if(testString.indexOf(term) === -1) {
            visibleTickets[i] = "remove";
            break;
          }
        }
      }
    }

    // Filter by date;
    for (let i = 0; i < visibleTickets.length; i++){
      if(this.state.startDate){
        if(visibleTickets[i].bizdate < this.state.startDate) {
          visibleTickets[i] = "remove";
        }
      }
      if(this.state.endDate){
        if(visibleTickets[i].bizdate > this.state.endDate){
          visibleTickets[i] = "remove";
        }
      }
    }

    // Filter based on header.
    // debugger;
    // console.log('preSort visibleTickets: ', visibleTickets);
    visibleTickets = sortData(visibleTickets, this.state.sortOptions);
    // console.log('postSort visibleTickets: ', visibleTickets);

    // debugger;
    this.setState({
      visibleTickets: visibleTickets.filter(ticket => typeof ticket !== "string"),
    })
  }

  headerOnClickHandler(column){
    const newSortOptions = {
      column: column,
    };

    const {
      sortOptions: oldSortOptions
    } = this.state;
  
    if (oldSortOptions.column === newSortOptions.column) {
      if (oldSortOptions.direction === 'asc') {
        newSortOptions.direction = 'desc';
      } else {
        newSortOptions.direction = 'asc';
      }
    } else {
      newSortOptions.direction = 'desc';
    }

    console.log(newSortOptions);

    this.setState({
      sortOptions: newSortOptions,
    }, () => {
      this.filterTickets();
    })

    // const sortedData = sortData(this.state.tickets, column, this.state.sortOptions);

    // console.log(sortedData.tickets);

    // this.setState({
    //   tickets: sortedData.tickets,
    //   sortOptions: sortedData.sortOptions,
    // }, () => {
    //   this.filterTickets();
    // })
  }

  render(){
    const searchTerms = this.state.searchTerms.map((term, index) => {
      return (
        <OptionPill selected={true} key={index} text={term} onClick={this.termOnClickHandler.bind(this, term)}/>
      )
    })
    return (
      <div className="non-standard-ticket-management">
        <div className="table-header">
          <div className="option-section">
            <div className="section-header">
              Select Stores
            </div>
            <StoreSelector stores={this.state.storeOptions} selectedStores={this.state.selectedStores} updateSelectedStores={this.updateState.bind(this, "selectedStores")}/>
          </div>

          <div className="option-section">
            <div className="section-header">
              Select Status
            </div>
            <StatusSelector statuses={this.state.statusOptions} selectedStatuses={this.state.selectedStatuses} updateSelectedStatuses={this.updateState.bind(this, "selectedStatuses")}/>
          </div>

          <div className="dates">
            <div className="start-date">
              <span>{this.state.startDate || "Select Start Date"}</span>
              <span className="calendar-icon" onClick={this.toggleCalendar.bind(this, "showStartCalendar")}><CalendarIcon/></span>  
            </div>

            <div className="start-date">
              <span>{this.state.endDate || "Select End Date"}</span>
              <span className="calendar-icon" onClick={this.toggleCalendar.bind(this, "showEndCalendar")}><CalendarIcon/></span>  
            </div>
          </div>
          
          <div className="search-section">
            <div>
              Search for Terms
            </div>
            <SearchInput onEnterHandler={this.onEnterHandler}/>

            <div className="search-terms">
              {searchTerms}
            </div>
          </div>

          <div className="button" onClick={this.saveCrap}>
            Save Button
          </div>

        </div>
        
        <div className="table-information">
          {this.state.loading ? 
            <div>
              <LoadingPuffIcon hexColor="#0077D7"/>
              <div>Calm Down Broseph</div>
            </div>
          :
            <NonStandardTicketsTable sortOptions={ this.state.sortOptions } headerOnClickHandler={ this.headerOnClickHandler } onChangeHandler={this.rowChangesChangeHandler} stores={this.state.storeOptions} tickets={this.state.visibleTickets}/>
          }

        </div>

        { this.state.showStartCalendar &&
          <Modal close={this.toggleCalendar.bind(this, "showStartCalendar")}>
            <CalendarDatePicker onDateSelect={this.onDateSelect.bind(this, "startDate")}/>
          </Modal>
        }

        { this.state.showEndCalendar &&
          <Modal close={this.toggleCalendar.bind(this, "showEndCalendar")}>
            <CalendarDatePicker onDateSelect={this.onDateSelect.bind(this, "endDate")}/>
          </Modal>
        }

        { this.state.processing &&
          <Modal>
            <LoadingPuffIcon hexColor="#999"/>
          </Modal>
        }
      </div>
    )
  }
}

class StoreSelector extends React.Component {
  constructor(props){
    super(props);
  }

  onClickHandler(store){
    const index = arrayIncludes(this.props.selectedStores, store);
    var selectedStores = cloneArray(this.props.selectedStores);

    if(index === -1){
      selectedStores.push(store);
    } else {
      selectedStores.splice(index, 1);
    }

    this.props.updateSelectedStores(selectedStores);
  }

  render(){
    const stores = this.props.stores.map((store, index) => {
      var selected = arrayIncludes(this.props.selectedStores, store) > -1 ? true : false;

      return (
        <OptionPill selected={selected} key={index} text={store.stores_name} onClick={this.onClickHandler.bind(this, store)}/>
      )
    })

    return (
      <div className="option-pills">
        {stores}
      </div>
    )
  }
}

class StatusSelector extends React.Component {
  constructor(props){
    super(props);
  }

  onClickHandler(status){
    const index = arrayIncludes(this.props.selectedStatuses, status);
    var selectedStatuses = cloneArray(this.props.selectedStatuses);
    console.log("status Index: ", index);
    if(index === -1){
      selectedStatuses.push(status);
    } else {
      selectedStatuses.splice(index, 1);
    }

    this.props.updateSelectedStatuses(selectedStatuses);
  }

  render(){
    const statuses = this.props.statuses.map((status, index) => {
      var selected = arrayIncludes(this.props.selectedStatuses, status) > -1 ? true : false;

      return (
        <OptionPill selected={selected} key={index} text={status} onClick={this.onClickHandler.bind(this, status)}/>
      )
    })

    return (
      <div className="option-pills">
        {statuses}
      </div>
    )
  }
}