export default {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://5epxeuyu7j.execute-api.us-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_urBVJCggQ",
    APP_CLIENT_ID: "104msgaveqr44tf7nic4htgaj6",
    IDENTITY_POOL_ID: "us-west-2:cc5dd25c-e7c0-436b-a850-60b3969a26d5"
  }
};
