// import _ from 'lodash';

import { cloneObject } from "../helpers/GeneralHelpers";

// These functions will create queries based on information that is given to them. 
export const StandardQueries = {
  update: function(tableName, identifier, params, columns){
    console.log('update');
    let paramsCopy = cloneObject(params);
    let query = `UPDATE ${tableName}`

    // Create where Statement
    let whereStatement = "WHERE";
    for(let i = 0; i < identifier.length; i++){
      whereStatement += " " + identifier[i] + " = " + params[identifier[i]];

      if(i + 1 < identifier.length){
        whereStatement += " AND ";
      }
      delete paramsCopy[identifier[i]];
    }

    // Create set Statement
    let setStatement = "SET";
    for(let key in paramsCopy){
      setStatement += " " + key + " = " + paramsCopy[key] + ",";
    }

    setStatement = setStatement.slice(0, -1);

    query = query + " " + setStatement + " " + whereStatement;
    console.log('query: ', query);
    return query;
  },
  find: function(tableName, params, columns, targetColumns){
    let targetQuery = '';
    if (targetColumns) {
      targetColumns.forEach((target, index) => {
        targetQuery += target;
        if (index !== targetColumns.length - 1) {
          targetQuery += ', ';
        }
      })
    } else {
      targetQuery = '*';
    }


    var query = `SELECT ${ targetQuery } FROM ${tableName}`;
    if(params){
      query = query + " WHERE ";

      var counter = 0;
      for(var key in params){
        if(columns.indexOf(key) !== -1){
          if(counter !== 0) query = query + " AND ";
          
          // Handles unique case of BI_Date
          if(key === "BI_Date") {
            query += "Date(" + key + ")" + " IN (";
          } else if(key === "bizdate_start"){
            debugger;
            
          } else if (key === "bizdate_end"){
            debugger;
          } else {
            query += key + " IN (";
          }
          
          // If params aren't in an array (meaning its only one object) then make it an array of length 1
          if(!Array.isArray(params[key])){
            params[key] = [params[key]];
          }

          params[key].forEach((element, index, array) => {
            if(typeof element === "string") {
              query += "'" + element + "'";
            } else {
              query += element;
            }

            if(index !== array.length-1){
              query += ', ';
            }
          })

          query += ')'
          counter++;
        }
      }
    } 
    query += ";";

    return query;
  },  
}

// export StandardQueries;