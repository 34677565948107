import QueryHelpers from "../helpers/QueryHelpers";
import Queries from "./Queries";

const columns = ["idSkills", "skill"];

const SkillsQueries = {
  find: function(params){
    var query = Queries.find("Skills", params, columns);

    // console.log(query);
    return QueryHelpers.runSelectAPI(query);
  },
}

export default SkillsQueries;