import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./js/containers/Home";
import NotFound from "./js/containers/NotFound";
import Login from "./js/containers/Login";
// import AppliedRoute from "./js/components/AppliedRoute";
import AppliedRoute from "./js/components/AppliedRoute";
import NewBiEntry from "./js/containers/NewBiEntry";
import ScheduleInputContainer from "./js/containers/ScheduleInput";
import DailyBI from "./js/containers/DailyBI";
import DailyDashboard from "./js/containers/DailyDashboard";
import Productivity from "./js/containers/Productivity";
import Inventory from "./js/containers/Inventory";
import BlueLineCsvUpload from "./js/containers/BlueLineCsvUpload";
import NonStandardTickets from "./js/containers/NonStandardTickets";

import { OvenScheduleInputContainer } from './components/pages/oven-schedule-input/OvenScheduleInputContainer';

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/bientry" exact component={NewBiEntry} props={childProps} />
    {/* Missing exact prop because this component will have more nested routes withint it */}
    <AppliedRoute path="/oven-schedule-input" component={OvenScheduleInputContainer} props={childProps} />
    <AppliedRoute path="/schedule-input" component={ScheduleInputContainer} props={childProps} />
    <AppliedRoute path="/daily-bi" component={DailyBI} props={childProps}/>
    <AppliedRoute path="/daily-dashboard" component={DailyDashboard} props={childProps}/>
    <AppliedRoute path="/productivity" component={Productivity} props={childProps}/>
    <AppliedRoute path="/inventory" component={Inventory} props={childProps}/>
    <AppliedRoute path="/bluelinecsv" component={BlueLineCsvUpload} props={childProps}/>
    <AppliedRoute path="/non-standard" component={NonStandardTickets} props={childProps}/>

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
