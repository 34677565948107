import React from "react";
import { Route, Switch } from "react-router-dom";
import Calendar from "react-calendar"; 

import { OvenScheduleTable } from './OvenScheduleTable';

import { formatDate } from 'helpers/GeneralHelpers';

export var OvenScheduleInputContainer = () => {
  return (
    <>
      <Switch>
        <Route path={`/oven-schedule-input/:date`} component={ OvenScheduleTable }/>
        <Route component={ ScheduleInputCalendar }/>
      </Switch>
    </>
  );
}

var ScheduleInputCalendar = (props) => {
  function onChangeHandler(date){
    var newDate = formatDate(date);
    props.history.push(`${props.match.url}/${newDate}`);
  }

  return (
    <div className="schedule-input-calendar">
      <Calendar 
        value={new Date()}
        onChange={ onChangeHandler } 
        tileClassName={({ date, view }) => view === 'month' && date.getDay() !== 2 ? 'disabled' : null}
        calendarType="US"/>
    </div>
  );
}

