import React from 'react';

import './modal-content-styles.scss';
export function MarkCompleteFinished(props) {
  const {
    recordCount,
    updateState,
  } = props;

  function closeModal() {
    updateState('modalState', null);
    updateState('recordCount', null);
  }

  return (
    <div className="mark-complete-finished">
      <div className="text">
        { `${ recordCount || 0 } record${ recordCount === 1 ? '' : 's' } were marked as complete` }
      </div>

      <div className="button" onClick={ closeModal }>
        Close
      </div>
    </div>
  )
}