import React, { Component } from "react";

import NonStandardTicketsTableHeaders from "./NonStandardTicketsTableHeaders";

import NonStandardTicketsTableRow from "./NonStandardTicketsTableRow";

import "../../css/components/non-standard-tickets-table.scss";

export default class NonStandardTicketsTable extends Component {
  render(){
    const rows = this.props.tickets.map(ticket => {
      const index = this.props.stores.map(store => store.idStores.toString()).indexOf(ticket.store);
      // const index = this.props.stores.map(store => store.Stores_qbo_Customer_id.toString()).indexOf(ticket.store);
      const store = this.props.stores[index];
      return (
        <NonStandardTicketsTableRow onChangeHandler={this.props.onChangeHandler} store={store} key={ticket.order_id} ticket={ticket}/>
      )
    })

    return (
      <div className="non-standard-tickets-table">
        <NonStandardTicketsTableHeaders sortOptions={ this.props.sortOptions } headerOnClickHandler={ this.props.headerOnClickHandler } />
        {rows}
      </div>
    )
  }
}