import React, { useState } from 'react';

import { API } from "aws-amplify";

import './modal-content-styles.scss';
export function SkyviaCheck(props) {
  const [count, setCount] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const {
    recordCount,
    updateState,
  } = props;

  function compareCount() {    
    if (parseInt(recordCount) === parseInt(count)) {
      updateState('modalState', 'loading');
      markComplete();
    } else {
      setFieldError(true);
    }
  }

  function markComplete() {
    API.post("mi6-bi", "/qbo-dailies/update").then(result => {
      updateState('modalState', 'mark-complete-finished');
      updateState('recordCount', result);
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
    });
  }

  return (
    <div className="skyvia-check">
      <div className="text">
        { `There ${ recordCount === 1 ? 'is' : 'are' } ${ recordCount || 0 } record${ recordCount === 1 ? '' : 's' } ready to process.` }
      </div>
      <div className="text">
        Please process the records in Skyvia then input the number of records prepared by Skyvia below.
      </div>
      <div className="inputs-row">
        <input className={ `input ${ fieldError ? 'input--error' : '' }` } value={ count } onChange={ e => setCount(e.target.value) } />
        <div className="button" onClick={ compareCount }>
          Complete
        </div>
      </div>
    </div>
  )
}