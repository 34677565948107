import QueryHelpers from "../helpers/QueryHelpers";

const columns = ["id", "date", "week_end", "year", "quarter", "period", "week", "day", "dow"];

const PeriodsQueries = {
  find: function(params){
    var query = "SELECT * FROM Periods";
    if(params){
      query = query + " WHERE (";

      var counter = 0;
      for(var key in params){
        if(columns.indexOf(key) !== -1){
          if(counter !== 0) query = query + " AND ";

          if(typeof params[key] === "string") {
            query = query + key + " = '" + params[key] + "'";
          } else {
            query = query + key + " = " + params[key];
          }
          
          counter++;
        }
      }
      query = query + ")";
    } 
    query = query + ";";

    // console.log(query);
    return QueryHelpers.runSelectAPI(query);
  },
}

export default PeriodsQueries;