import React from 'react';

export default class TriangleIcon extends React.Component {
  render() {
    let points = "520 5 515 11 525 11";

    if (this.props.direction === "down") {
      points = "520 11 515 5 525 5";
    }

    return (
      <svg className={ `triangle-icon ${ this.props.className ? this.props.className : '' }` } width="10px" height="6px" viewBox="0 0 10 6">
        <g id="Finals" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Discovery-Skills-Copy" transform="translate(-1257.000000, -615.000000)" fill="#0077D7">
                <g id="Group-5" transform="translate(742.000000, 610.000000)">
                    <polygon id="Triangle-2-Copy-9" points={ points }></polygon>
                </g>
            </g>
        </g>
      </svg>
    );
  }
}
