import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./Login.css";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      needsNewPassword: false,
      email: "",
      password: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      user: {}
    };
  }

  validateForm() {
    return this.state.needsNewPassword ? this.newPasswordValidation() : this.loginValidation();
  }

  loginValidation = () => (this.state.email.length > 0 && this.state.password.length > 0);

  newPasswordValidation = () => (this.state.oldPassword.length > 0 && this.state.newPassword.length > 0 && this.state.confirmNewPassword.length > 0 && this.state.newPassword === this.state.confirmNewPassword);

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleNewPasswordSubmit = async event => {
    event.preventDefault();

    const { user, newPassword } = this.state;

    this.setState({ isLoading: true });

    try {
      const loggedUser = await Auth.completeNewPassword(
        user,
        newPassword
      );
      if (loggedUser) {
        this.props.userHasAuthenticated(true);
      }
      this.props.history.push("/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  handleLoginSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
  
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      this.setState({ user });
      console.log(user);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ needsNewPassword: true });
        this.setState({ isLoading: false });
      } else {
        this.props.userHasAuthenticated(true);
        this.props.history.push("/");
      }
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderLogin = () => {
    return (
    <form onSubmit={this.handleLoginSubmit}>
      <FormGroup controlId="email" bsSize="large">
        <ControlLabel>Email</ControlLabel>
        <FormControl
          autoFocus
          type="email"
          value={this.state.email}
          onChange={this.handleChange}
        />
      </FormGroup>
      <FormGroup controlId="password" bsSize="large">
        <ControlLabel>Password</ControlLabel>
        <FormControl
          value={this.state.password}
          onChange={this.handleChange}
          type="password"
        />
      </FormGroup>
      <LoaderButton
        block
        bsSize="large"
        disabled={!this.validateForm()}
        type="submit"
        isLoading={this.state.isLoading}
        text="Login"
        loadingText="Logging in…"
      />
    </form>
    );
  }

  renderNewPassword = () => {
    return (
      <form onSubmit={this.handleNewPasswordSubmit}>
        <FormGroup controlId="oldPassword" bsSize="large">
          <ControlLabel>Current Password</ControlLabel>
          <FormControl
            autoFocus
            type="password"
            value={this.state.oldPassword}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="newPassword" bsSize="large">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            value={this.state.newPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmNewPassword" bsSize="large">
          <ControlLabel>Confirm New Password</ControlLabel>
          <FormControl
            value={this.state.confirmNewPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Change Password"
          loadingText="Changing password..."
        />
      </form>
    );
  }
  
  render() {
    return (
      <div className="Login">
        {this.state.needsNewPassword ? this.renderNewPassword() : this.renderLogin()}
      </div>
    );
  }
}
