import React, { Component } from "react";

import SettingVariables from "../../SettingVariables";

import ProductivityRecordsTable from "./ProductivityRecordsTable";
import Modal from "./Modal";
import ProductivityCreateRecord from "./ProductivityCreateRecord";

import BIQueries from "../queries/BIQueries";

import "../../css/components/productivity-management.scss";
import "../../css/global/buttons.scss";

export default class ProductivityManagement extends Component {
  constructor(props){
    super(props);

    this.toggleModal = this.toggleModal.bind(this);

    this.state = {
      showModal: false,
      productivityRecords: [],
    }

    BIQueries.find({ idAPI: SettingVariables.idAPIConverter(53)}).then(result => {
      this.setState({
        productivityRecords: result,
      })
    })
  }

  toggleModal(){
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  render() {
    return (
      <div className="productivity-management">
        <div className="productivity-management__page-headers">
          <div className="page-headers__page-title">
            Productivity Management
          </div>
          <div className="page-headers__create-record button" onClick={this.toggleModal}>
            Create Record
          </div>
        </div>
        
        <div>Search and Filters</div>

        <ProductivityRecordsTable productivityRecords={this.state.productivityRecords}/>

        {this.state.showModal && 
          <Modal close={this.toggleModal}>
            <ProductivityCreateRecord close={this.toggleModal}/>
          </Modal>
        }

      </div>
    );
  }
}
