import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./BlueLineCsvUpload.css";
import sha256 from 'crypto-js/sha256';
import { API } from "aws-amplify";
import _ from 'lodash';
import { CSVReader } from 'react-papaparse';
import Papa from 'papaparse';
import moment from 'moment';

import StoreQueries from "../queries/StoreQueries";
import BLConfigQueries from "../queries/BLConfigQueries";

export default class BlueLineCsvUpload extends Component {
  constructor(props) {
    super(props);

    this.file = null;
    this.fileInput = React.createRef();

    this.state = {
      isLoading: null,
      stores: [],
      config: [],
    };

    BLConfigQueries.find().then(config => {
      this.setState({
        config: config,
      })
    }
    )

    StoreQueries.find().then(stores => {
      this.setState({
        stores: stores,
      })
    })
  }

  handleReadCSV = (data) => {
    let i;
    let j = 0;
    let headers = {};
    let invoices = {};

    for (i = 0; i < data.data[0].length; i++) {
      let header = data.data[1][i];
      if (data.data[0][i] !== "") {
        header = data.data[0][i] + " " + header;
      }
      headers[header] = i;
    }
    let invoiceHeaderIndex = this.getHeaderIndex(headers, "INVOICE#");

    data.data.forEach(row => {
      if (j !== 0 && j !== 1 && j !== (data.data.length - 1)) {
        let invoiceNumber = row[invoiceHeaderIndex];
        if (!(invoiceNumber in invoices)) {
          invoices[invoiceNumber] = [row];
        } else {
          invoices[invoiceNumber].push(row);
        }
      }
      j++;
    });

    Object.keys(invoices).forEach(invoiceNumber => {
      let invoiceDate = invoices[invoiceNumber][0][headers["INVOICE DATE"]];
      invoiceDate = invoiceDate.slice(0,4) + "-" + invoiceDate.slice(4,6) + "-" + invoiceDate.slice(6) + " 00:00:00";
      let storeByCustomerNum = this.findBy(this.state.stores, "Stores_BL_CustomerNum", invoices[invoiceNumber][0][headers["CUSTOMER#"]]);
      let vendorRefId = storeByCustomerNum.Stores_BLRefID;
      let aPAccountRefId = storeByCustomerNum.Stores_APAccountRefId;
      var totalAmt;
      let dueDate = moment(invoiceDate, "YYYY-MM-DD").add(7, 'days').format("YYYY-MM-DD 00:00:00")
      let line = "[";
      for (i = 0; i < invoices[invoiceNumber].length; i++) {
        let configByCode = this.findBy(this.state.config, "Code", invoices[invoiceNumber][i][headers["CATEGORY CODE"]]);
        if (invoices[invoiceNumber][i][headers["INVOICE TYPE"]] === "T") {
          totalAmt = invoices[invoiceNumber][i][headers["CATEGORY TOTAL"]]
        } else {
          line += `{"id":"${i + 1}", "LineNum": ${`${i + 1}.0`}, "Amount": ${invoices[invoiceNumber][i][headers["CATEGORY TOTAL"]]}, "DetailType": "AccountBasedExpenseLineDetail", "AccountBasedExpenseLineDetail_ClassRefId": "${storeByCustomerNum.Stores_qbo_classrefid}", "AccountBasedExpenseLineDetail_AccountRefId": "${configByCode.QboAccountRefId}", "AccountBasedExpenseLineDetail_BillableStatus": "${configByCode.Billable}", "AccountBasedExpenseLineDetail_TaxCodeRefId": "${configByCode.TaxCode}"},`
        }
      }
      line = line.substring(0, line.length - 1);
      line += "]";
      let query = "INSERT INTO `BLImports` (`DocNumber`, `TxnDate`, `Line`, `VendorRefId`, `APAccountRefId`, `TotalAmt`, `DueDate`) VALUES ('" + invoiceNumber + "', '" + invoiceDate + "', '" + line + "', '" + vendorRefId + "', '" + aPAccountRefId + "', " + totalAmt + ", '" + dueDate + "')"
      try {
        this.singleEntry(query, storeByCustomerNum.Stores_qbo_Customer_id);
      } catch (e) {
        alert(e);
      }
    })

    alert("Successfully Submitted all invoices");
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
  }

  getHeaderIndex = (headers, header) => {
    return headers[header];
  }

  findBy = (arrayOfObjects, objectProperty, matchValue) => {
    let i;
    for (i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i][objectProperty] == matchValue) {
        return arrayOfObjects[i];
      }
    }
    return {};
  }

  singleEntry(query, storeId) {
    const dateTimeStamp = + new Date();

    // let payload = {"query": query}
    let payload = {"queries": [query], "store": storeId, "datestamp": dateTimeStamp};

    // for the select only api use "/single/untitled" instead of "/untitled"
    return API.post("mi6-bi", "/untitled", {
      body: payload,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).catch(error => {
      console.log(error.response, "ERROR MESSAGE>>>>");
    });
  }

  render() {
    return (
      <div className="BlueLineCsvUpload">
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
          // configOptions={{header: true}}
        />
        <LoaderButton
          block
          bsSize="large"
          // type="submit"
          isLoading={this.state.isLoading}
          text="Choose BlueLine CSV"
          loadingText="Sending Data…"
          onClick={this.handleImportOffer}
        />
      </div>
    );
  }
}

