import { cloneArray } from "../helpers/GeneralHelpers";

import APIQueries from '../queries/APIQueries';

const varidAPI = {
  "Daily Before Open": {api: 55, time: "10:00:00"},
  "Daily Mid Open": {api: 55, time: "3:00:00"},
  "Daily Close": {api:55, time: "23:00:00"},
  "Weekly": {api: 56}
}

const varWasteidAPI = {
  "Daily Before Open": {api: 59, time: "10:00:00"},
  "Daily Mid Open": {api: 59, time: "3:00:00"},
  "Daily Close": {api:59, time: "23:00:00"},
  "Weekly": {api: 60},
}


const InventoryHelpers = {
  formatDataForCreate: function(currentState){
    debugger;
    const store = currentState.selectedStore;
    const date = currentState.selectedDate;
    const countType = currentState.selectedCountType;

    const rows =currentState.rows;

    var formattedData = [];

    var idAPIs = [];
    
    // Add var apis to list
    for(let varCountType in varidAPI){
      if(idAPIs.indexOf(varCountType.api) === -1){
        idAPIs.push(varCountType.api);
      }
    }

    // Add var w/waste to list

    for(let varWasteCountType in varWasteidAPI){
      if(idAPIs.indexOf(varWasteCountType.api) === -1){
        idAPIs.push(varWasteCountType.api);
      }
    }

    debugger;

    return APIQueries.find({idAPI: [55, 56, 59, 60]}).then(apiResults => {
      rows.forEach(row => {

        const apis = apiResults.map(r => r.idAPI);
        

        if(row["Var"]){
          const index = apis.indexOf(varidAPI[countType].api);
          let tempObject = {
            BI_externalid: store,
            BI_externalid2: row.Ingredient,
            BI_externalid3: row.Unit,
            BI_externalid4: countType,
            BI_Date: date + " " + varidAPI[countType].time,
            idAPI: varidAPI[countType].api,
            BI_fieldname: apiResults[index].API_Name,
            BI_qty: row["Var"],
          }
  
          formattedData.push(tempObject);
        }
        if(row["Var w/Waste"]){
          const index = apis.indexOf(varWasteidAPI[countType].api);
          let tempObject = {
            BI_externalid: store,
            BI_externalid2: row.Ingredient,
            BI_externalid3: row.Unit,
            BI_externalid4: countType,
            BI_Date: date + " " + varWasteidAPI[countType].time,
            idAPI: varWasteidAPI[countType].api,
            BI_fieldname: apiResults[index].API_Name,
            BI_qty: row["Var w/Waste"],
          }
  
          formattedData.push(tempObject);
        }
      })
      return formattedData;
    })

    



   
  },

}

export default InventoryHelpers;