export const getLaborChartRecord = (value, laborChart) => {
  let laborChartRecord;
  if (value <= laborChart[0].oven_count) {
    laborChartRecord = laborChart[0];
  } else if (value >= laborChart[laborChart.length - 1]) {
    laborChartRecord = laborChart[laborChart.length - 1];
  } else {
    laborChartRecord = laborChart.filter(r => r.oven_count === parseInt(value))[0];
  }

  return laborChartRecord;
}