import PeriodsQueries from '../queries/PeriodsQueries';
import BIQueries from '../queries/BIQueries';
import APIQueries from '../queries/APIQueries';
import { formatDate, cloneArray } from './GeneralHelpers';

const apiQtyVsAmount = {
  '% Put into Portal Before Promise Time': 'BI_qty',
  '% Put into Portal on Time': 'BI_qty',
  'Actual Charted Hours': 'BI_qty',
  'Cash Over/Under': 'BI_amount',
  'Customer Complaints': 'BI_qty',
  'Portal Failures': 'BI_qty',
  'Projected Sales': 'BI_amount',
  'Total Portal Eligible Transactions': 'BI_qty',
}

const DailyBIHelpers = {
  calculateSysSalesProjected: function(storeName, date){
    return this.getPastSales(date).then(result => {
      return BIQueries.findPastSalesForProjectedSales(storeName, result).then(answer => {
        let average = 0;
        if(answer){
          for(let i = 0; i < answer.length; i++){
            average = average + parseFloat(answer[i].sales);
          }
  
          average = average / answer.length;
        }
        
        
        return average.toFixed(2);
      });
    });
    

  },
  getPastSales: function(date){
    return PeriodsQueries.find({date: date}).then(periods => {
      let dates = [periods[0].date];

      let oneWeekBack = new Date(periods[0].date + " 00:00");
      for (let i = 0; i < 6; i++) {
        oneWeekBack.setDate(oneWeekBack.getDate() - 7);
        let newDate = formatDate(oneWeekBack);
        dates.push(newDate);
      }

      return PeriodsQueries.find({year: periods[0].year - 1, day: periods[0].day}).then(oneYearAgo => {
        if(oneYearAgo.length > 0){
          dates.push(oneYearAgo[0].date)
        }
        return dates;
      })
    })
  },
  formatDataForCreate: function(originalData){
    // Remove rows that haven't been edited.
    let data = cloneArray(originalData);
    let formatedData = [];
    // Objects that have less then 5 attributes haven't been edited yet
    // The five initial attributes are storeName, date, Actual Charted Hours, Actual Sales, Projected Sales.
    // This doesn't account for the rows that already have information in the database 
    let filterdArray = data.filter(element => Object.keys(element).length > 5);

    let arrayLengths = filterdArray.map(function(a){return Object.keys(a).length;});

    // Find the object with the most changes and gathers all the api information for those columns;
    // this will fail under specific circumstances if columns are only partially filled.
    // let largestObjectIndex = arrayLengths.indexOf(Math.max.apply(Math, arrayLengths));

    let idAPIs = {};

    // let apiNames = Object.keys(filterdArray[largestObjectIndex]);

    // 
    let apiNames = ["storeName", "date", "Actual Sales", "Actual Charted Hours", "Projected Sales", "Cash Over/Under", "Customer Complaints", "Total Portal Eligible Transactions", "% Put into Portal on Time", "% Put into Portal Before Promise Time", "Portal Failures"];

    return APIQueries.find({API_Name: apiNames}).then(results => {
      results.forEach(element => {
        idAPIs[element.API_Name] = element.idAPI;
      })

      return idAPIs;
    }).then(() => {

      filterdArray.forEach(element => {

        if(element["Total Portal Eligible Transactions"] || element["Total Portal Eligible Transactions"] === 0){
          if(element["% Put into Portal on Time percent"] || element["% Put into Portal on Time percent"] === 0){
            element["% Put into Portal on Time"] = Math.round(element["% Put into Portal on Time percent"] * .01 * element["Total Portal Eligible Transactions"]);

            if(element["% Put into Portal Before Promise Time percent"] || element["% Put into Portal Before Promise Time percent"] === 0) {
              element["% Put into Portal Before Promise Time"] = Math.round(element["% Put into Portal Before Promise Time percent"] * .01 * element["% Put into Portal on Time"])
            }
          }
        }

        let storeName = element.storeName;
        let date = element.date;
        let dataObject = { storeName: element.storeName, date: element.date, data:[]};
      
        for(let key in element){
          if(Object.keys(idAPIs).indexOf(key) !== -1){
            if(element[key] || element[key] === 0){
              let tempObject = {
                BI_externalid: storeName,
                BI_Date: date,
                idAPI: idAPIs[key],
                BI_fieldname: key,
                // apiQtyVsAmount[key]: element[key],
              }
  
              tempObject[apiQtyVsAmount[key]] = element[key];
  
              dataObject.data.push(tempObject);
            }
          }
        }
        formatedData.push(dataObject);
      })

      return formatedData;
    })
  },
}

export default DailyBIHelpers;