import React, { Component } from "react";

import "../../css/global/main.scss";
import "../../css/components/inventory-management.scss";

import SettingVariables from "../../SettingVariables";

import InventoryQueries from "../queries/InventoryQueries";
import StoreQueries from "../queries/StoreQueries";
import BIQueries from "../queries/BIQueries";

import { formatDate, cloneArray } from "../helpers/GeneralHelpers";
import InventoryHelpers from "../helpers/InventoryHelpers";

import CalendarDatePicker from "./CalendarDatePicker";
import Modal from "./Modal";

import CalendarIcon from "../icons/CalendarIcon";
import LoadingPuffIcon from "../icons/LoadingPuffIcon";

// const countTypes = [
//   {
//     name: "Daily Before Open",
//     idAPIs: [55],
//     time: ""
//   },

//   {
//     name: "Daily Mid Open",
//     idAPIs: [55],
//     time: ""
//   },

//   {
//     name: "Daily Close",
//     idAPIs: [55],
//     time: ""
//   },

//   {
//     name: "Weekly",
//     idAPIs: [55],
//   }
// ]

export default class InventoryManagement extends Component {
  constructor(props){
    super(props);

    this.getInventoryInformation = this.getInventoryInformation.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggleShowCalendar = this.toggleShowCalendar.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onRowChange = this.onRowChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.createEmptyRows = this.createEmptyRows.bind(this);

    this.state = {
      showCalendar: false,
      inventory: [],
      stores: [],
      selectedStore: "",
      countTypes: ["Daily Before Open", "Daily Mid Open", "Daily Close", "Weekly"],
      selectedCountType: "Daily Before Open",
      selectedDate: "",
      rows: [],
      loading: false,
      disableButton: true,
    }
  }

  createEmptyRows(){
      // Create rows for the table;
      // These rows will be updated in the getInventoryInformation function if records are found.
    const rows = [];
    this.state.inventory.forEach(item => {
      const tempObject = {};
      tempObject["Ingredient"] = item.Ingredient;
      tempObject["Unit"] = item.Unit;
      tempObject["Var"] = "";
      tempObject["Var w/Waste"] = "";
      rows.push(tempObject);
    })
    this.setState({
      rows: rows,
    })
  }

  componentDidMount(){
    InventoryQueries.find({ Type: 1 }).then(result => {
      // Create rows for the table;
      // These rows will be updated in the getInventoryInformation function if records are found.
      const rows = [];

      result.forEach(result => {
        const tempObject = {};
        tempObject["Ingredient"] = result.Ingredient;
        tempObject["Unit"] = result.Unit;
        tempObject["Var"] = "";
        tempObject["Var w/Waste"] = "";

        rows.push(tempObject);
      })

      this.setState({
        rows: rows,
        inventory: result,
      })
    })

    StoreQueries.find().then(stores => {
      let storeNames = stores.map(store => store.stores_name);
      this.setState({
        stores: storeNames,
        selectedStore: storeNames[0],
      })
    })
  }

  getInventoryInformation(){
    console.log('getting info');
    this.setState({
      loading: true,
    })
    if(this.state.selectedStore && this.state.selectedCountType && this.state.selectedDate){
      BIQueries.find({ idAPI: [ SettingVariables.idAPIConverter(55), SettingVariables.idAPIConverter(56), SettingVariables.idAPIConverter(59), SettingVariables.idAPIConverter(60)], BI_externalid: this.state.selectedStore, BI_Date: this.state.selectedDate, BI_externalid4: this.state.selectedCountType }).then(results => {
        // Parse through each record and update the correct object in this.state.rows.
        var currentRows = cloneArray(this.state.rows);

        for(let result of results){
          const ingredient = result.BI_externalid2;
          const ingredientArray = currentRows.map(row => row.Ingredient);
          const index = ingredientArray.indexOf(ingredient);

          if(result.idAPI === SettingVariables.idAPIConverter(55) || result.idAPI === SettingVariables.idAPIConverter(56)){
            currentRows[index]["Var"] = parseFloat(result.BI_qty).toFixed(2);
          }
          if(result.idAPI === SettingVariables.idAPIConverter(59) || result.idAPI === SettingVariables.idAPIConverter(60)){
            currentRows[index]["Var w/Waste"] = parseFloat(result.BI_qty).toFixed(2);
          }
        }

        this.setState({
          rows: currentRows,
          loading: false,
        })
      })
    }
  }

  toggleShowCalendar(){
    this.setState({
      showCalendar: !this.state.showCalendar,
    })
  }

  updateState(e){
    if(this.state[e.target.id] === e.target.value){
      console.log('this shouldnt update');
      return null;
    }
    this.setState({
      [e.target.id]: e.target.value,
    }, () => {
      this.createEmptyRows();
      this.getInventoryInformation();
    })
  }

  onDateSelect(date){
    if(date === this.state.selectedDate){
      console.log('this shouldnt update');
      return null;
    }
    const newDate = formatDate(date);
    this.setState({
      selectedDate: newDate,
    }, () => {
      this.toggleShowCalendar();
      this.createEmptyRows();
      this.getInventoryInformation();
    })
  }

  onRowChange(index, e){
    var currentRows = cloneArray(this.state.rows);

    currentRows[index][e.target.name] = e.target.value;

    this.setState({
      rows: currentRows,
      disableButton: false,
    })
  }

  saveChanges(){
    InventoryHelpers.formatDataForCreate(this.state).then(results => {
      debugger;
      BIQueries.create(results).then(createResult => {
        debugger;
        if(createResult.indexOf(results.length) === -1) window.alert("Error Occurred. Please check database for results.")

        this.setState({
          disableButton: true,
        })
      });

    })
  }

  render(){
    if(this.state.stores.length === 0 || this.state.inventory.length === 0) {
      return (
        <div>
          Pump the breaks dude. 
        </div>
      )
    }

    const storesIndex = this.state.stores.map(store => {
      return (
        <option key={store}>{store}</option>
      )
    })
    
    const countTypeOptions = this.state.countTypes.map(countType => {
      return (
        <option key={countType}>{countType}</option>
      )
    })

    const inventoryRows = this.state.rows.map((row, index) => {
      return (
        <InventoryRow key={index} rowInfo={row} rowIndex={index} onRowChange={this.onRowChange.bind(this, index)}/>
      )
    })

    return (
      <div className="inventory-management">
        <div className="page-header">
          Inventory
        </div>

        <div className="options">
          <select id="selectedStore" onChange={this.updateState}>
            {storesIndex}
          </select>

          <span>{this.state.selectedDate || "Select Date"}</span>

          <span className="calendar-icon" onClick={this.toggleShowCalendar}><CalendarIcon/></span>
          
          <select id="selectedCountType" onChange={this.updateState}>
            {countTypeOptions}
          </select>
        </div>

        { (this.state.selectedDate && this.state.loading === false) && 
          <div className="inventory-table">
            <div className="inventory-row headers">
              <div className="column header ingredient">ingredient</div>
              <div className="column header var">var</div>
              <div className="column header var-waste">var w/waste</div>
              <div className="column header unit">unit</div>
            </div>
            {inventoryRows}
          </div>
        }

        { (this.state.selectedDate && this.state.loading) &&
          <div className="loading">
            <LoadingPuffIcon hexColor="#0077D7"/> <span>Calm down Chase its loading. Don't worry it won't take this long to load normally your dad just needs to fix some stuff on the backend.</span>
          </div>
        }
        

        <div className={`button${this.state.disableButton ? ' disabled':''}`} onClick={this.saveChanges}>
          Save Changes
        </div>

        { this.state.showCalendar &&
          <Modal close={this.toggleShowCalendar}>
            <CalendarDatePicker onDateSelect={this.onDateSelect}/>
          </Modal>
        }
      
      </div>
    )
  }
}

class InventoryRow extends React.Component {
  render(){
    return (
      <div className="inventory-row">
        <div className="column ingredient">
          {this.props.rowInfo["Ingredient"]}
        </div>

        <div className="column var">
          <input name="Var" value={this.props.rowInfo["Var"]} onChange={this.props.onRowChange}/>
        </div>
        
        <div className="column var-waste">
          <input name="Var w/Waste" value={this.props.rowInfo["Var w/Waste"]} onChange={this.props.onRowChange}/>
        </div>
        
        <div className="column unit">
          {this.props.rowInfo["Unit"]}
        </div>
      </div>
    )
  }
}