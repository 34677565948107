import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ProductivityManagement from "../components/ProductivityManagement";

export default class Productivity extends Component {

  render() {
    return (
      <div className="test">
        <Switch>
          <Route component={ProductivityManagement}/>
        </Switch>
      </div>
    );
  }
}
