import React, { Component } from "react";

export default class Calendar extends Component {
  render() {
    // const height = this.props.height ? this.props.height : 46;
    // const width = this.props.width ? this.props.width : 46;
    // var colorFill;
    // if (this.props.colorFill) {
    //   colorFill = this.props.colorFill;
    // } else if (this.props.color) {
    //   colorFill = this.props.color
    // } else {
    //   colorFill = `#878787`
    // }

    return (
      <svg width="19px" height="18px" viewBox="0 0 19 18">
          <g id="Finals-(Desktop)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
              <g id="Event-Registration-Payment-Method-(Purchase-Order)" transform="translate(-136.000000, -283.000000)" stroke={this.props.color ? this.props.color : '#141414'} strokeWidth="1.3068">
                  <g id="Group-12" transform="translate(100.000000, 180.000000)">
                      <g id="Group-14" transform="translate(35.000000, 35.000000)">
                          <g id="Group-7">
                              <g id="Group-9" transform="translate(0.000000, 61.000000)">
                                  <g id="Calendar-2" transform="translate(0.000000, 5.000000)">
                                      <g id="Group-2" transform="translate(1.968750, 2.625000)">
                                          <path d="M15.75,15.75 L1.3125,15.75 C0.590625,15.75 0,15.159375 0,14.4375 L0,2.625 C0,1.903125 0.590625,1.3125 1.3125,1.3125 L15.75,1.3125 C16.471875,1.3125 17.0625,1.903125 17.0625,2.625 L17.0625,14.4375 C17.0625,15.159375 16.471875,15.75 15.75,15.75 Z M0,5.25 L17.0625,5.25 L0,5.25 Z M3.9375,3.28125 L3.9375,0 L3.9375,3.28125 Z M13.125,3.28125 L13.125,0 L13.125,3.28125 Z" id="Stroke-1"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  }i
}