import QueryHelpers from "../helpers/QueryHelpers";
import Queries from "./Queries";

const columns = ["idInventory", "Ingredient", "Unit", "Type", "Active", "sort_order"];

const InventoryQueries = {
  find: function(params){
    var query = Queries.find("Inventory", params, columns);

    return QueryHelpers.runSelectAPI(query);
  },
}

export default InventoryQueries;