const LaborChartHelpers = {
  getLaborChartRecord: function(sales){
    let roundedSales = LaborChartHelpers.roundSales(sales);
    let laborChartRecord;
    
    if(sales >= LaborChartHelpers.LaborChart[LaborChartHelpers.LaborChart.length - 1].Sales){
      laborChartRecord = LaborChartHelpers.LaborChart[LaborChartHelpers.LaborChart.length - 1];
    } else if (sales < LaborChartHelpers.LaborChart[0].Sales) {
      laborChartRecord = LaborChartHelpers.LaborChart[0];
    } else {
      laborChartRecord = LaborChartHelpers.LaborChart.filter(record => record.Sales === roundedSales)[0];
    }

    return laborChartRecord;
  },
  roundSales: function(sales){
    let roundedSales = parseFloat(sales);
    if(sales % 50 !== 0){
      roundedSales = Math.ceil(sales / 50) * 50 - 50;
    }

    return roundedSales;
  },
  LaborChart: [
    {
      "idLabor_Chart" : 0,
      "Sales" : 1000,
      "Hours" : 33,
      "$LH" : 30.30,
      "active" : 0
    },
    {
      "idLabor_Chart" : 2,
      "Sales" : 1050,
      "Hours" : 34,
      "$LH" : 30.88,
      "active" : 0
    },
    {
      "idLabor_Chart" : 3,
      "Sales" : 1100,
      "Hours" : 35,
      "$LH" : 31.43,
      "active" : 0
    },
    {
      "idLabor_Chart" : 4,
      "Sales" : 1150,
      "Hours" : 36,
      "$LH" : 31.94,
      "active" : 0
    },
    {
      "idLabor_Chart" : 5,
      "Sales" : 1200,
      "Hours" : 37,
      "$LH" : 32.43,
      "active" : 0
    },
    {
      "idLabor_Chart" : 6,
      "Sales" : 1250,
      "Hours" : 38,
      "$LH" : 32.89,
      "active" : 0
    },
    {
      "idLabor_Chart" : 7,
      "Sales" : 1300,
      "Hours" : 39,
      "$LH" : 33.33,
      "active" : 0
    },
    {
      "idLabor_Chart" : 8,
      "Sales" : 1350,
      "Hours" : 40,
      "$LH" : 33.75,
      "active" : 0
    },
    {
      "idLabor_Chart" : 9,
      "Sales" : 1400,
      "Hours" : 41,
      "$LH" : 34.15,
      "active" : 0
    },
    {
      "idLabor_Chart" : 10,
      "Sales" : 1450,
      "Hours" : 42,
      "$LH" : 34.52,
      "active" : 0
    },
    {
      "idLabor_Chart" : 11,
      "Sales" : 1500,
      "Hours" : 43,
      "$LH" : 34.88,
      "active" : 0
    },
    {
      "idLabor_Chart" : 12,
      "Sales" : 1550,
      "Hours" : 44,
      "$LH" : 35.23,
      "active" : 0
    },
    {
      "idLabor_Chart" : 13,
      "Sales" : 1600,
      "Hours" : 45,
      "$LH" : 35.56,
      "active" : 0
    },
    {
      "idLabor_Chart" : 14,
      "Sales" : 1650,
      "Hours" : 46,
      "$LH" : 35.87,
      "active" : 0
    },
    {
      "idLabor_Chart" : 15,
      "Sales" : 1700,
      "Hours" : 46,
      "$LH" : 36.96,
      "active" : 0
    },
    {
      "idLabor_Chart" : 16,
      "Sales" : 1750,
      "Hours" : 47,
      "$LH" : 37.23,
      "active" : 0
    },
    {
      "idLabor_Chart" : 17,
      "Sales" : 1800,
      "Hours" : 47,
      "$LH" : 38.30,
      "active" : 0
    },
    {
      "idLabor_Chart" : 18,
      "Sales" : 1850,
      "Hours" : 47,
      "$LH" : 39.36,
      "active" : 0
    },
    {
      "idLabor_Chart" : 19,
      "Sales" : 1900,
      "Hours" : 47,
      "$LH" : 40.43,
      "active" : 0
    },
    {
      "idLabor_Chart" : 20,
      "Sales" : 1950,
      "Hours" : 48,
      "$LH" : 40.63,
      "active" : 0
    },
    {
      "idLabor_Chart" : 21,
      "Sales" : 2000,
      "Hours" : 48,
      "$LH" : 41.67,
      "active" : 0
    },
    {
      "idLabor_Chart" : 22,
      "Sales" : 2050,
      "Hours" : 49,
      "$LH" : 41.84,
      "active" : 0
    },
    {
      "idLabor_Chart" : 23,
      "Sales" : 2100,
      "Hours" : 49,
      "$LH" : 42.86,
      "active" : 0
    },
    {
      "idLabor_Chart" : 24,
      "Sales" : 2150,
      "Hours" : 49,
      "$LH" : 43.88,
      "active" : 0
    },
    {
      "idLabor_Chart" : 25,
      "Sales" : 2200,
      "Hours" : 49,
      "$LH" : 44.90,
      "active" : 0
    },
    {
      "idLabor_Chart" : 26,
      "Sales" : 2250,
      "Hours" : 50,
      "$LH" : 45.00,
      "active" : 0
    },
    {
      "idLabor_Chart" : 27,
      "Sales" : 2300,
      "Hours" : 51,
      "$LH" : 45.10,
      "active" : 0
    },
    {
      "idLabor_Chart" : 28,
      "Sales" : 2350,
      "Hours" : 51,
      "$LH" : 46.08,
      "active" : 0
    },
    {
      "idLabor_Chart" : 29,
      "Sales" : 2400,
      "Hours" : 52,
      "$LH" : 46.15,
      "active" : 0
    },
    {
      "idLabor_Chart" : 30,
      "Sales" : 2450,
      "Hours" : 52,
      "$LH" : 47.12,
      "active" : 0
    },
    {
      "idLabor_Chart" : 31,
      "Sales" : 2500,
      "Hours" : 52,
      "$LH" : 48.08,
      "active" : 0
    },
    {
      "idLabor_Chart" : 32,
      "Sales" : 2550,
      "Hours" : 52,
      "$LH" : 49.04,
      "active" : 0
    },
    {
      "idLabor_Chart" : 33,
      "Sales" : 2600,
      "Hours" : 52,
      "$LH" : 50.00,
      "active" : 0
    },
    {
      "idLabor_Chart" : 34,
      "Sales" : 2650,
      "Hours" : 53,
      "$LH" : 50.00,
      "active" : 0
    },
    {
      "idLabor_Chart" : 35,
      "Sales" : 2700,
      "Hours" : 53,
      "$LH" : 50.94,
      "active" : 0
    },
    {
      "idLabor_Chart" : 36,
      "Sales" : 2750,
      "Hours" : 54,
      "$LH" : 50.93,
      "active" : 0
    },
    {
      "idLabor_Chart" : 37,
      "Sales" : 2800,
      "Hours" : 55,
      "$LH" : 50.91,
      "active" : 0
    },
    {
      "idLabor_Chart" : 38,
      "Sales" : 2850,
      "Hours" : 55,
      "$LH" : 51.82,
      "active" : 0
    },
    {
      "idLabor_Chart" : 39,
      "Sales" : 2900,
      "Hours" : 56,
      "$LH" : 51.79,
      "active" : 0
    },
    {
      "idLabor_Chart" : 40,
      "Sales" : 2950,
      "Hours" : 56,
      "$LH" : 52.68,
      "active" : 0
    },
    {
      "idLabor_Chart" : 41,
      "Sales" : 3000,
      "Hours" : 56,
      "$LH" : 53.57,
      "active" : 0
    },
    {
      "idLabor_Chart" : 42,
      "Sales" : 3050,
      "Hours" : 57,
      "$LH" : 53.51,
      "active" : 0
    },
    {
      "idLabor_Chart" : 43,
      "Sales" : 3100,
      "Hours" : 57,
      "$LH" : 54.39,
      "active" : 0
    },
    {
      "idLabor_Chart" : 44,
      "Sales" : 3150,
      "Hours" : 58,
      "$LH" : 54.31,
      "active" : 0
    },
    {
      "idLabor_Chart" : 45,
      "Sales" : 3200,
      "Hours" : 59,
      "$LH" : 54.24,
      "active" : 0
    },
    {
      "idLabor_Chart" : 46,
      "Sales" : 3250,
      "Hours" : 59,
      "$LH" : 55.08,
      "active" : 0
    },
    {
      "idLabor_Chart" : 47,
      "Sales" : 3300,
      "Hours" : 60,
      "$LH" : 55.00,
      "active" : 0
    },
    {
      "idLabor_Chart" : 48,
      "Sales" : 3350,
      "Hours" : 60,
      "$LH" : 55.83,
      "active" : 0
    },
    {
      "idLabor_Chart" : 49,
      "Sales" : 3400,
      "Hours" : 61,
      "$LH" : 55.74,
      "active" : 0
    },
    {
      "idLabor_Chart" : 50,
      "Sales" : 3450,
      "Hours" : 61,
      "$LH" : 56.56,
      "active" : 0
    },
    {
      "idLabor_Chart" : 51,
      "Sales" : 3500,
      "Hours" : 62,
      "$LH" : 56.45,
      "active" : 0
    },
    {
      "idLabor_Chart" : 52,
      "Sales" : 3550,
      "Hours" : 62,
      "$LH" : 57.26,
      "active" : 0
    },
    {
      "idLabor_Chart" : 53,
      "Sales" : 3600,
      "Hours" : 62,
      "$LH" : 58.06,
      "active" : 0
    },
    {
      "idLabor_Chart" : 54,
      "Sales" : 3650,
      "Hours" : 62,
      "$LH" : 58.87,
      "active" : 0
    },
    {
      "idLabor_Chart" : 55,
      "Sales" : 3700,
      "Hours" : 63,
      "$LH" : 58.73,
      "active" : 0
    },
    {
      "idLabor_Chart" : 56,
      "Sales" : 3750,
      "Hours" : 63,
      "$LH" : 59.52,
      "active" : 0
    },
    {
      "idLabor_Chart" : 57,
      "Sales" : 3800,
      "Hours" : 63,
      "$LH" : 60.32,
      "active" : 0
    },
    {
      "idLabor_Chart" : 58,
      "Sales" : 3850,
      "Hours" : 64,
      "$LH" : 60.16,
      "active" : 0
    },
    {
      "idLabor_Chart" : 59,
      "Sales" : 3900,
      "Hours" : 64,
      "$LH" : 60.94,
      "active" : 0
    },
    {
      "idLabor_Chart" : 60,
      "Sales" : 3950,
      "Hours" : 64,
      "$LH" : 61.72,
      "active" : 0
    },
    {
      "idLabor_Chart" : 61,
      "Sales" : 4000,
      "Hours" : 64,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 62,
      "Sales" : 4050,
      "Hours" : 65,
      "$LH" : 62.31,
      "active" : 0
    },
    {
      "idLabor_Chart" : 63,
      "Sales" : 4100,
      "Hours" : 65,
      "$LH" : 63.08,
      "active" : 0
    },
    {
      "idLabor_Chart" : 64,
      "Sales" : 4150,
      "Hours" : 65,
      "$LH" : 63.85,
      "active" : 0
    },
    {
      "idLabor_Chart" : 65,
      "Sales" : 4200,
      "Hours" : 66,
      "$LH" : 63.64,
      "active" : 0
    },
    {
      "idLabor_Chart" : 66,
      "Sales" : 4250,
      "Hours" : 67,
      "$LH" : 63.43,
      "active" : 0
    },
    {
      "idLabor_Chart" : 67,
      "Sales" : 4300,
      "Hours" : 68,
      "$LH" : 63.24,
      "active" : 0
    },
    {
      "idLabor_Chart" : 68,
      "Sales" : 4350,
      "Hours" : 69,
      "$LH" : 63.04,
      "active" : 0
    },
    {
      "idLabor_Chart" : 69,
      "Sales" : 4400,
      "Hours" : 69,
      "$LH" : 63.77,
      "active" : 0
    },
    {
      "idLabor_Chart" : 70,
      "Sales" : 4450,
      "Hours" : 70,
      "$LH" : 63.57,
      "active" : 0
    },
    {
      "idLabor_Chart" : 71,
      "Sales" : 4500,
      "Hours" : 71,
      "$LH" : 63.38,
      "active" : 0
    },
    {
      "idLabor_Chart" : 72,
      "Sales" : 4550,
      "Hours" : 72,
      "$LH" : 63.19,
      "active" : 0
    },
    {
      "idLabor_Chart" : 73,
      "Sales" : 4600,
      "Hours" : 73,
      "$LH" : 63.01,
      "active" : 0
    },
    {
      "idLabor_Chart" : 74,
      "Sales" : 4650,
      "Hours" : 74,
      "$LH" : 62.84,
      "active" : 0
    },
    {
      "idLabor_Chart" : 75,
      "Sales" : 4700,
      "Hours" : 75,
      "$LH" : 62.67,
      "active" : 0
    },
    {
      "idLabor_Chart" : 76,
      "Sales" : 4750,
      "Hours" : 76,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 77,
      "Sales" : 4800,
      "Hours" : 77,
      "$LH" : 62.34,
      "active" : 0
    },
    {
      "idLabor_Chart" : 78,
      "Sales" : 4850,
      "Hours" : 78,
      "$LH" : 62.18,
      "active" : 0
    },
    {
      "idLabor_Chart" : 79,
      "Sales" : 4900,
      "Hours" : 79,
      "$LH" : 62.03,
      "active" : 0
    },
    {
      "idLabor_Chart" : 80,
      "Sales" : 4950,
      "Hours" : 79,
      "$LH" : 62.66,
      "active" : 0
    },
    {
      "idLabor_Chart" : 81,
      "Sales" : 5000,
      "Hours" : 80,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 82,
      "Sales" : 5050,
      "Hours" : 81,
      "$LH" : 62.35,
      "active" : 0
    },
    {
      "idLabor_Chart" : 83,
      "Sales" : 5100,
      "Hours" : 82,
      "$LH" : 62.20,
      "active" : 0
    },
    {
      "idLabor_Chart" : 84,
      "Sales" : 5150,
      "Hours" : 85,
      "$LH" : 60.59,
      "active" : 0
    },
    {
      "idLabor_Chart" : 85,
      "Sales" : 5200,
      "Hours" : 82,
      "$LH" : 63.41,
      "active" : 0
    },
    {
      "idLabor_Chart" : 86,
      "Sales" : 5250,
      "Hours" : 83,
      "$LH" : 63.25,
      "active" : 0
    },
    {
      "idLabor_Chart" : 87,
      "Sales" : 5300,
      "Hours" : 84,
      "$LH" : 63.10,
      "active" : 0
    },
    {
      "idLabor_Chart" : 88,
      "Sales" : 5350,
      "Hours" : 85,
      "$LH" : 62.94,
      "active" : 0
    },
    {
      "idLabor_Chart" : 89,
      "Sales" : 5400,
      "Hours" : 86,
      "$LH" : 62.79,
      "active" : 0
    },
    {
      "idLabor_Chart" : 90,
      "Sales" : 5450,
      "Hours" : 87,
      "$LH" : 62.64,
      "active" : 0
    },
    {
      "idLabor_Chart" : 91,
      "Sales" : 5500,
      "Hours" : 88,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 92,
      "Sales" : 5550,
      "Hours" : 89,
      "$LH" : 62.36,
      "active" : 0
    },
    {
      "idLabor_Chart" : 93,
      "Sales" : 5600,
      "Hours" : 90,
      "$LH" : 62.22,
      "active" : 0
    },
    {
      "idLabor_Chart" : 94,
      "Sales" : 5650,
      "Hours" : 90,
      "$LH" : 62.78,
      "active" : 0
    },
    {
      "idLabor_Chart" : 95,
      "Sales" : 5700,
      "Hours" : 91,
      "$LH" : 62.64,
      "active" : 0
    },
    {
      "idLabor_Chart" : 96,
      "Sales" : 5750,
      "Hours" : 92,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 97,
      "Sales" : 5800,
      "Hours" : 93,
      "$LH" : 62.37,
      "active" : 0
    },
    {
      "idLabor_Chart" : 98,
      "Sales" : 5850,
      "Hours" : 94,
      "$LH" : 62.23,
      "active" : 0
    },
    {
      "idLabor_Chart" : 99,
      "Sales" : 5900,
      "Hours" : 95,
      "$LH" : 62.11,
      "active" : 0
    },
    {
      "idLabor_Chart" : 100,
      "Sales" : 5950,
      "Hours" : 95,
      "$LH" : 62.63,
      "active" : 0
    },
    {
      "idLabor_Chart" : 101,
      "Sales" : 6000,
      "Hours" : 96,
      "$LH" : 62.50,
      "active" : 0
    },
    {
      "idLabor_Chart" : 102,
      "Sales" : 6050,
      "Hours" : 97,
      "$LH" : 62.37,
      "active" : 0
    },
    {
      "idLabor_Chart" : 103,
      "Sales" : 6100,
      "Hours" : 98,
      "$LH" : 62.24,
      "active" : 0
    }
  ],
  
  
}

module.exports = LaborChartHelpers;