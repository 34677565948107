const SettingVariables = {
  // options used to change from 'development' and 'production'.
  mode: 'production',


  idAPIConverter: function(idAPI){
    if(SettingVariables.mode === 'development') idAPI = idAPI + 100;
    return idAPI;
  }
}

export default SettingVariables;  