import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import ScheduleInputCalendar from "../components/ScheduleInputCalendar";
import ScheduleInputTable from "../components/ScheduleInputTable";


export default class ScheduleInput extends Component {

  render() {
    return (
      <div className="test">
        <Switch>
          <Route path={`/schedule-input/:date`} component={ScheduleInputTable}/>
          <Route component={ScheduleInputCalendar}/>
        </Switch>
      </div>
    );
  }
}
