import React from 'react';

import LoadingPuffIcon from '../../../icons/LoadingPuffIcon';

export function WaitingForResponse(props) {
  return (
    <div className="waiting-for-response">
      <div className="header">
        Waiting for response...
      </div>
      <LoadingPuffIcon hexColor="#0077D7" />
    </div>
  )
}