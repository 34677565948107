import QueryHelpers from "../helpers/QueryHelpers";
import Queries from "./Queries";

const columns = ["idAPI", "API_Name"];

const APIQueries = {
  find: function(params){
    var query = Queries.find("API", params, columns);

    // console.log(query);
    return QueryHelpers.runSelectAPI(query);
  },
}

export default APIQueries;