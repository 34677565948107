import React, { Component } from "react";
import Calendar from "react-calendar";

export default class CalendarInputRow extends React.Component {
  constructor(props){
    super(props);
    this.onDateSelect = this.onDateSelect.bind(this);
    // this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  onDateSelect(date){
    // const newDate = formatDate(date);

    this.props.onDateSelect(date);
  }

  // onChangeHandler(e){
  //   const tempRecord = Object.assign({}, this.props.record);
  //   tempRecord[this.props.databaseColumn] = e.target.value;
  //   this.props.updateState({
  //     record: tempRecord,
  //   })
  // }

  render(){
    return (
      <div className="input-fields__row">
        <Calendar 
          value={new Date()}
          onChange={this.onDateSelect} 
          calendarType="US"
        />
      </div>
    )
  }
}