import React, { Component } from "react";

import "../../css/global/buttons.scss";
import "../../css/components/daily-bi-table.scss";

import { cloneArray } from "../helpers/GeneralHelpers";
// import ScheduleInputHelpers from "../helpers/ScheduleInputHelpers";
import DailyBIHelpers from "../helpers/DailyBIHelpers";
import QueryHelpers from '../helpers/QueryHelpers';

import ChevronIcon from "../icons/ChevronIcon";

import StoreQueries from '../queries/StoreQueries';
import BIQueries from "../queries/BIQueries";

import { dailyKPIUpdateQuery } from '../actions.js/DailyKPIUpdate';

import DailyBITableRow from "./DailyBITableRow";

export default class DailyBITable extends Component {

  constructor(props){
    super(props);

    this.getStores = this.getStores.bind(this);
    this.navigateBackToCalendar = this.navigateBackToCalendar.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.saveEdits = this.saveEdits.bind(this);

    var dates = [this.props.match.params.date];
    
    this.state = {
      tableRows: [],
      stores: [],
      dates: dates,
      disabledSaveButton: true,
    }

    this.getStores();
    
  }

  navigateBackToCalendar(){
    this.props.history.push(`/daily-bi`);
  }

  getStores(){
    StoreQueries.find().then(stores => {
      let storesArray = stores.map(store => { return { name: store.stores_name, id: store.idStores } });

      // let storeNames = stores.map(store => store.stores_name);
      this.setState({
        stores: storesArray,
      })
    })
  }

  componentDidUpdate(){
    if(this.state.dates.length > 0 && this.state.stores.length > 0 && this.state.tableRows.length === 0){
      let stores = this.state.stores;
      let dates = this.state.dates;
      var tableRows = [];

      for(let i = 0; i < stores.length; i++){
        for (let j = 0; j < dates.length; j++){
          tableRows.push({ id: stores[i].id, storeName: stores[i].name, date: dates[j] });
        }
      }

      this.setState({
        tableRows: tableRows,
      })
    }
  }

  updateRow(storeName, date, updateInfo){
    var newTableRows = cloneArray(this.state.tableRows);
    var targetRow = newTableRows.filter(element => element.storeName === storeName && element.date === date)[0];

    for(var key in updateInfo){
      if(typeof updateInfo[key] === "string") updateInfo[key] = parseFloat(updateInfo[key]);
      targetRow[key] = updateInfo[key];
    }

    this.setState({
      tableRows: newTableRows,
      disabledSaveButton: false,
    })
  }

  saveEdits(){
    DailyBIHelpers.formatDataForCreate(this.state.tableRows).then(formatResult => {
      let currentCount = formatResult.length;
      
      formatResult.forEach(r => {
        BIQueries.create(r.data).then(() => {
          currentCount--;
          if (currentCount === 0) {
            const dailyKPIQuery = dailyKPIUpdateQuery(this.props.match.params.date);
            
            QueryHelpers.runInsertAPI([dailyKPIQuery]);
          }
        });
      })

      this.setState({
        disabledSaveButton: true,
      })
    })
  }

  render() {
    if (this.state.tableRows.length === 0) return (
      <div>
        Still Loading Bro.
      </div>
    )

    const tableRows = this.state.tableRows.map((tableRow, index) => {
      return (
        <DailyBITableRow updateRow={this.updateRow} key={index} row={tableRow} storeName={tableRow.storeName} date={tableRow.date}/>
      )
    })

    return (
      <div className="daily-bi-table">
        <div className="page-title">
          Daily BI
        </div>
        <div className="daily-bi-table__back-button" onClick={this.navigateBackToCalendar}>
          <ChevronIcon  direction="left"/>
          Back to Date Select
        </div>

        <div>
          {this.props.match.params.date}
        </div>

        <table>
          <thead>
            <tr>
              <th>Store</th>
              <th>Actual Sales</th>
              <th>Projected Sales</th>
              <th>Actual Charted Hours</th>
              {/* <th>Total Portal Orders Eligible</th>
              <th>% in Portal</th>
              <th>% in Portal Before Promise Time</th>
              <th># of Portal Failures</th> */}
              <th>Cash +/-</th>
              <th># of Customer Complaints</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
          
        </table>

        <div className={`button daily-bi-table__save-edits${this.state.disabledSaveButton ? ' disabled':''}`} onClick={this.saveEdits}>
          Save Edits
        </div>
      </div>
    );
  }
}


// Math.ceil(number/ 50 ) * 50 - 50;