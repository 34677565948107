import React, { Component } from "react";

import SettingVariables from "SettingVariables";

import { getPreviousSales } from '../../Helpers';
import { getLaborChartRecord } from './helpers';

import LoadingPuffIcon from "js/icons/LoadingPuffIcon";
import BIQueries from "js/queries/BIQueries";

import { debounce } from 'lodash';

import './table-row.scss';
export class TableRow extends Component {

  constructor(props){
    super(props);

    this.projectedSalesChangeHandler = this.projectedSalesChangeHandler.bind(this);
    this.inputOnChangeHandler = this.inputOnChangeHandler.bind(this);

    this.state = {
      loading: true,
      dates: [],
      lastYearSales: '',
      sysSalesProjected: '',
      projectedSales: '',
      chartedHours: '',
      charter: '',
      scheduledHours: '',
    }

    getPreviousSales(props.store.idStores, props.date).then(result => {
      const updatedInfo = { 
        sysSalesProjected: result.sysSalesProjected,
        lastYearSales: result.lastYearsSales
      };
      props.updateRow(props.storeName, props.date, updatedInfo);

      this.setState({
        sysSalesProjected: result.sysSalesProjected,
        lastYearSales: result.lastYearsSales,
        loading: false,
      })
    });

    // This checks the input columns to see if that data already exists.
    
    const idAPIArray = [SettingVariables.idAPIConverter(81), SettingVariables.idAPIConverter(44), SettingVariables.idAPIConverter(45)];

    BIQueries.find({BI_externalid: props.storeName, BI_Date: props.date, idAPI: idAPIArray}).then(result => {
      if(result && result.length > 0) {

        var updateInfo = {};
        result.forEach(r => {
          updateInfo[r.BI_fieldname] =  r.BI_amount || r.BI_qty;
        })
        
        this.props.updateRow(this.props.storeName, this.props.date, updateInfo);
      }
    })
  }

  projectedSalesChangeHandler = (e) => {
    e.persist();

    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        let laborChartRecord = getLaborChartRecord(e.target.value, this.props.ovenCountLaborChart);

        var updateInfo = {
          "Projected Oven Count": e.target.value,
          "Charted Hours": laborChartRecord ? laborChartRecord.hours : '',
        }

        this.props.updateRow(this.props.storeName, this.props.date, updateInfo)

        this.setState({
          "Projected Oven Count": e.target.value,
          "Charted Hours": laborChartRecord ? laborChartRecord.hours : '',
        })
      }, 1500)
    }

    this.debouncedFn();
  }

  inputOnChangeHandler(e){
    this.setState({
      [e.target.id]: e.target.value,
    })

    var updateInfo = {}
    updateInfo[e.target.id] = e.target.value;

    this.props.updateRow(this.props.storeName, this.props.date, updateInfo)
  }

  render(){
    var date = new Date(this.props.date + " 00:00");

    if(this.state.loading){
      return (
        <div className="table-row loading">
          <LoadingPuffIcon hexColor="#0077D7"/>
        </div>
      )
    } else {
      return (
        <div className="table-row">
          <div className="table-row__date">
            {date.toLocaleDateString()}
          </div>
          <div className="table-row__sys-sales-projected">
            {this.props.tableRow.sysSalesProjected || ''}
          </div>
          <div className="table-row__last-year-sales">
            {this.props.tableRow.lastYearSales || ''}
          </div>
          <div className="table-row__projected-sales">
            <input type="number" id="Projected Oven Count" defaultValue={this.props.tableRow["Projected Oven Count"] || ''} onChange={this.projectedSalesChangeHandler}/>
          </div>
          <div className="table-row__charted-hours">
            {this.props.tableRow["Charted Hours"] || ''}
          </div>
          <div className="table-row__scheduled-hours">
            <input type="number" id="Scheduled Hours" value={this.props.tableRow['Scheduled Hours'] || ''} onChange={this.inputOnChangeHandler}/>
          </div>
        </div>
      )
    }
    
  }
}