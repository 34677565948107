
export function formatDataForFind(stores, statuses, startDate, endDate) {
  var params = {};

  if(stores) {
    params.store = [];
    for(let i = 0; i < stores.length; i++){
      params.store.push(stores[i].idStores);
    }
  }

  if (statuses) {
    params.Action = statuses;
  }

  if(startDate){
    params.bizdate_start = startDate;
  }

  if(endDate){
    params.bizdate_end = endDate;
  }
  
  return params;
}

export function sortData(data, sortOptions) {
  const {
    column,
    direction,
  } = sortOptions;

  data.sort(function(a, b) {
    if(a[column] > b[column]) {
      if(direction === 'asc') return 1;
      if(direction === 'desc') return -1;
    }

    if(a[column] < b[column]) {
      if(direction === 'asc') return -1;
      if(direction === 'desc') return 1;
    }
    return 0;
  });

  const sortedTickets = data;
  return sortedTickets;
}