import React, { Component } from "react";

import "../../css/components/productivity-records-table.scss";

import { formatMS } from "../helpers/GeneralHelpers";

export default class ProductivityRecordsTable extends Component {
  
  render() {
    const rows = this.props.productivityRecords.map((record, index) => {
      return (
        <ProductivityRow key={index} record={record}/>
      )
    })
    return (
      <div className="productivity-records-table">
        {rows}
      </div>
    );
  }
} 


class ProductivityRow extends Component {
  render(){
    return (
      <div className="productivity-row">
        <div className="productivity-row__column">
          {this.props.record.BI_Date}
        </div>
        <div className="productivity-row__column">
          {this.props.record.BI_externalid}
        </div>
        <div className="productivity-row__column">
          {this.props.record.BI_externalid2}
        </div>
        <div className="productivity-row__column">
          {this.props.record.BI_externalid3}
        </div>
        <div className="productivity-row__column">
          {formatMS(this.props.record.BI_qty)}
        </div>
      </div>
    )
  }
}
