import QueryHelpers from "../helpers/QueryHelpers";
import Queries from "./Queries";

const columns = ["store", "order_id", "bizdate", "Action", "qbo_ref"];

const NonstandardTicketsQueries = {
  find: function(params){
    console.log("NonstandardTicketsQueries.find")
    var query = Queries.find("Nonstandard_tickets", params, columns);
    return QueryHelpers.runSelectAPI(query);
  },
  update: function(params){
    console.log("NonstandardTicketsQueries.update");
    var query = Queries.update("Nonstandard_tickets", ["order_id", "store"], params, columns);

    // var query = "INSERT INTO `Nonstandard_tickets` (order_id, qbo_ref, action) values (102176, 12345, 'Completed') ON DUPLICATE KEY UPDATE qbo_ref = 12345"
    debugger;


    return QueryHelpers.runInsertAPI([query], "3394-0001 Grand Island");
    
  }
}

export default NonstandardTicketsQueries;