import QueryHelpers from "../helpers/QueryHelpers";

import Queries from "./Queries";

const columns = ["idBL Config", "Code", "Description", "QboAccountRefId", "TaxCode", "QBOAccountRefName"]

const BLConfigQueries = {
  find: function(params){
    var query = Queries.find("BLConfig", params, columns);

    // console.log(query);
    return QueryHelpers.runSelectAPI(query);
  },
}

export default BLConfigQueries;
