export function dailyKPIUpdateQuery(date){
  return (
    `INSERT INTO grow_prod.daily_summary
    (storeid,
    bizdatesale,
    lastyear,
    sales_projection,
    oven_count_proj,
    tickets,
    netsales,
    oven_count,
    pizza_items,
    pizza_net,
    pizza_taxable,
    pizza_taxexempt,
    hnr_items,
    hnr_net,
    hnr_taxable,
    hnr_taxexempt,
    bread_items,
    bread_net,
    bread_taxable,
    bread_taxexempt,
    wings_items,
    wings_net,
    wings_taxable,
    wings_taxexempt,
    soda_items,
    soda_net,
    soda_taxable,
    soda_taxexempt,
    other_items,
    other_net,
    other_taxable,
    other_taxexempt,
    nonfood_items,
    nonfood_net,
    nonfood_taxable,
    nonfood_taxexempt,
    delivery_fee_net,
    delivery_fee_taxable,
    delivery_fee_taxexempt,
    service_fee_net,
    service_fee_taxable,
    service_fee_taxexempt,
    smallorder_fee_net,
    smallorder_fee_taxable,
    smallorder_fee_taxexempt,
    tip_fee_net,
    tip_fee_taxable,
    tip_fee_taxexempt,
    breadattach,
    brokenpromise,
    pickup,
    HNR,
    waiting,
    mobile,
    web,
    drivethru,
    portal,
    delivery,
    late,
    failed,
    digital,
    refund,
    system_void,
    void,
    cash,
    credit,
    gift_card,
    cust_account,
    epay,
    other,
    po,
    checks,
    over_under,
    over_under_calc,
    deposit_dt,
    deposittype,
    amt,
    verify_amt,
    manager,
    transnum,
    verified,
    actual_hours,
    scheduled_hours,
    schedule_charted_hours,
    actual_charted_hours,
    inventory_box_variance,
    inventory_cheese_variance,
    customer_complaint,
    hero_notice)
    (SELECT
        orders.storeid,
        bizdatesale,
        p2.date as lastyear,
        bi.amount as sales_projection,
        bi8.amount as oven_count_proj,
        o6.items as tickets,
        o6.nettransactions as netsales,
        o.items + o1.items + o2.items as oven_count,
        o.items as pizza_items,
        o.nettransactions as pizza_net,
        o.taxable as pizza_taxable,
        o.taxexempt as pizza_taxexempt,
        o1.items as hnr_items,
        o1.nettransactions as hnr_net,
        o1.taxable as hnr_taxable,
        o1.taxexempt as hnr_taxexempt,
        o2.items as bread_items,
        o2.nettransactions as bread_net,
        o2.taxable as bread_taxable,
        o2.taxexempt as bread_taxexempt,
        o3.items as wings_items,
        o3.nettransactions as wings_net,
        o3.taxable as wings_taxable,
        o3.taxexempt as wings_taxexempt,
        o4.items as soda_items,
        o4.nettransactions as soda_net,
        o4.taxable as soda_taxable,
        o4.taxexempt as soda_taxexempt,
        o5.items as other_items,
        o5.nettransactions as other_net,
        o5.taxable as other_taxable,
        o5.taxexempt as other_taxexempt,
        o11.items as nonfood_items,
        o11.nettransactions as nonfood_net,
        o11.taxable as nonfood_taxable,
        o11.taxexempt as nonfood_taxexempt,
        o7.nettransactions as delivery_fee_net,
        o7.taxable as delivery_fee_taxable,
        o7.taxexempt as delivery_fee_taxexempt,
        o8.nettransactions as service_fee_net,
        o8.taxable as service_fee_taxable,
        o8.taxexempt as service_fee_taxexempt,
        o9.nettransactions as smallorder_fee_net,
        o9.taxable as smallorder_fee_taxable,
        o9.taxexempt as smallorder_fee_taxexempt,
        o10.nettransactions as tip_fee_net,
        o10.taxable as tip_fee_taxable,
        o10.taxexempt as tip_fee_taxexempt,
        sum(breadattach) as breadattach, 
        sum(brokenpromise) as brokenpromise, 
        sum(pickup) as pickup,
        SUM(CASE ordertype WHEN 'H' THEN 1 ELSE 0 END) as HNR,
        SUM(CASE ordertype WHEN 'W' THEN 1 ELSE 0 END) as waiting,
        SUM(CASE ordertype WHEN 'M' THEN 1 ELSE 0 END) as mobile,
        SUM(CASE ordertype WHEN 'O' THEN 1 ELSE 0 END) as web,
        SUM(CASE ordertype WHEN 'D' THEN 1 ELSE 0 END) as drivethru,
        SUM(CASE WHEN (digital IN (1,2,3,5,6,7)) THEN 1 ELSE 0 END) as portal,
        SUM(CASE WHEN (digital IN (1,2,3,4)) THEN 1 ELSE 0 END) as delivery,
        SUM(CASE WHEN (digital IN (2,6)) THEN 1 ELSE 0 END) as late,
        SUM(CASE WHEN (digital IN (1,5)) THEN 1 ELSE 0 END) as failed,
        SUM(CASE WHEN (digital IN (8)) THEN 1 ELSE 0 END) as digital,
        SUM(CASE WHEN voidrefund = 1 THEN 1 ELSE 0 END) as refund,
        SUM(CASE WHEN voidrefund = 2 THEN 1 ELSE 0 END) as system_void,
        SUM(CASE WHEN voidrefund = 3 THEN 1 ELSE 0 END) as void,
        t.verifyamount as cash,
        t1.verifyamount as credit,
        t2.verifyamount as gift_card,
        t3.verifyamount as cust_account,
        t4.verifyamount as epay,
        t5.verifyamount as other,
        t6.verifyamount as po,
        t8.verifyamount as checks,
        t7.amount as over_under,
        NULL as over_under_calc,
        d.deposit_dt,
        d.deposittype,
        d.amt,
        d.verify_amt,
        d.manager,
        d.transnum,
        CASE
            WHEN d.verify_amt IS NULL
                THEN NULL
                ELSE 1
        END AS verified,
        tc.actual_hours as actual_hours,
        bi2.qty as scheduled_hours,
        bi3.qty as schedule_charted_hours,
        # bi4.qty as actual_charted_hours,
        bi4.hours as actual_charted_hours,
        bi5.qty as inventory_box_variance,
        bi5a.qty as inventory_cheese_variance,
        bi6.qty as customer_complaint,
        bi6.qty as hero_notice
    FROM grow_prod.orders
    LEFT JOIN grow_prod.Periods p1
        ON orders.bizdatesale = p1.date
    LEFT JOIN grow_prod.Periods p2
        ON p1.day = p2.day AND p1.year = (p2.year+1)
    LEFT JOIN grow_prod.ordersummary o
        ON orders.storeid = o.storeid AND orders.bizdatesale = o.date AND o.accountnum = '02'
    LEFT JOIN grow_prod.ordersummary o1
        ON orders.storeid = o1.storeid AND orders.bizdatesale = o1.date AND o1.accountnum = '02.1'
    LEFT JOIN grow_prod.ordersummary o2
        ON orders.storeid = o2.storeid AND orders.bizdatesale = o2.date AND o2.accountnum = '03'
    LEFT JOIN grow_prod.ordersummary o3
        ON orders.storeid = o3.storeid AND orders.bizdatesale = o3.date AND o3.accountnum = '04'
    LEFT JOIN grow_prod.ordersummary o4
        ON orders.storeid = o4.storeid AND orders.bizdatesale = o4.date AND o4.accountnum = '06'
    LEFT JOIN grow_prod.ordersummary o5
        ON orders.storeid = o5.storeid AND orders.bizdatesale = o5.date AND o5.accountnum = '08'
    LEFT JOIN grow_prod.ordersummary o6
        ON orders.storeid = o6.storeid AND orders.bizdatesale = o6.date AND o6.accountnum = '1900'
    LEFT JOIN grow_prod.ordersummary o7
        ON orders.storeid = o7.storeid AND orders.bizdatesale = o7.date AND o7.accountnum = '990'
    LEFT JOIN grow_prod.ordersummary o8
        ON orders.storeid = o8.storeid AND orders.bizdatesale = o8.date AND o8.accountnum = '991'
    LEFT JOIN grow_prod.ordersummary o9
        ON orders.storeid = o9.storeid AND orders.bizdatesale = o9.date AND o9.accountnum = '992'
    LEFT JOIN grow_prod.ordersummary o10
        ON orders.storeid = o10.storeid AND orders.bizdatesale = o10.date AND o10.accountnum = '993'
    LEFT JOIN grow_prod.ordersummary o11
        ON orders.storeid = o11.storeid AND orders.bizdatesale = o11.date AND o11.accountnum = '09'
    LEFT JOIN grow_prod.ordertransactions t
        ON orders.storeid = t.storeid AND orders.bizdatesale = t.date AND t.paytype = 'Cash'
    LEFT JOIN grow_prod.ordertransactions t1
        ON orders.storeid = t1.storeid AND orders.bizdatesale = t1.date AND t1.paytype = 'Credit'
    LEFT JOIN grow_prod.ordertransactions t2
        ON orders.storeid = t2.storeid AND orders.bizdatesale = t2.date AND t2.paytype = 'Gift Card'
    LEFT JOIN grow_prod.ordertransactions t3
        ON orders.storeid = t3.storeid AND orders.bizdatesale = t3.date AND t3.paytype = 'Cust. Account'
    LEFT JOIN grow_prod.ordertransactions t4
        ON orders.storeid = t4.storeid AND orders.bizdatesale = t4.date AND t4.paytype = 'EPay'
    LEFT JOIN grow_prod.ordertransactions t5
        ON orders.storeid = t5.storeid AND orders.bizdatesale = t5.date AND t5.paytype = 'Other'
    LEFT JOIN grow_prod.ordertransactions t6
        ON orders.storeid = t6.storeid AND orders.bizdatesale = t6.date AND t6.paytype = 'PO'
    LEFT JOIN grow_prod.ordertransactions t7
        ON orders.storeid = t7.storeid AND orders.bizdatesale = t7.date AND t7.paytype = 'Over/Under'
    LEFT JOIN grow_prod.ordertransactions t8
        ON orders.storeid = t8.storeid AND orders.bizdatesale = t8.date AND t8.paytype = 'Checks'
    LEFT JOIN grow_prod.deposit d
        ON orders.storeid = d.storeid AND orders.bizdatesale = d.ds_dt
    LEFT JOIN grow_prod.Stores
        ON orders.storeid = Stores.idStores
    LEFT JOIN 
    (SELECT date, Stores.idStores as storeid, Sum(totalhours) as actual_hours
    FROM grow_prod.timeclock
    LEFT JOIN grow_prod.Stores
        ON timeclock.store = Stores.Altametrics_location
    GROUP BY store,date)tc
        ON orders.storeid = tc.storeid AND orders.bizdatesale = tc.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI IN (43)
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi
        ON orders.storeid = bi.storeid AND orders.bizdatesale = bi.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI IN (44)
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi2
        ON orders.storeid = bi2.storeid AND orders.bizdatesale = bi2.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI IN (45)
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi3
        ON orders.storeid = bi3.storeid AND orders.bizdatesale = bi3.date
    # LEFT JOIN
    # (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    # FROM grow_prod.BI
    # LEFT JOIN grow_prod.Stores
    #     ON BI.BI_externalid = Stores.stores_name
    # WHERE idAPI IN (48)
    # GROUP BY idAPI, BI_externalid, Date(BI_Date))bi4
    #     ON orders.storeid = bi4.storeid AND orders.bizdatesale = bi4.date
    LEFT JOIN grow_prod.Labor_Chart_oc bi4
		ON (o.items + o1.items + o2.items) = bi4.oven_count
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI = 59 AND BI_externalid2 = '14" Box'
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi5
        ON orders.storeid = bi5.storeid AND orders.bizdatesale = bi5.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI = 59 AND BI_externalid2 = 'Cheese'
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi5a
        ON orders.storeid = bi5a.storeid AND orders.bizdatesale = bi5a.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI = 57
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi6
        ON orders.storeid = bi6.storeid AND orders.bizdatesale = bi6.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI = 63
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi7
        ON orders.storeid = bi7.storeid AND orders.bizdatesale = bi7.date
    LEFT JOIN
    (SELECT idAPI, idStores as storeid, Date(BI_Date) as date, (BI_amount) as amount, Sum(BI_qty) as qty
    FROM grow_prod.BI
    LEFT JOIN grow_prod.Stores
        ON BI.BI_externalid = Stores.stores_name
    WHERE idAPI IN (81)
    GROUP BY idAPI, BI_externalid, Date(BI_Date))bi8
        ON orders.storeid = bi8.storeid AND orders.bizdatesale = bi8.date
    WHERE bizdatesale = "${ date }"
    Group by orders.storeid, bizdatesale
    ORDER BY storeid, bizdatesale)
    ON DUPLICATE KEY UPDATE 

    lastyear = values(lastyear),
    sales_projection = values(sales_projection),
    oven_count_proj = values(oven_count_proj),
    tickets = values(tickets),
    netsales = values(netsales),
    oven_count = values(oven_count),
    pizza_items = values(pizza_items),
    pizza_net = values(pizza_net),
    pizza_taxable = values(pizza_taxable),
    pizza_taxexempt = values(pizza_taxexempt),
    hnr_items = values(hnr_items),
    hnr_net = values(hnr_net),
    hnr_taxable = values(hnr_taxable),
    hnr_taxexempt = values(hnr_taxexempt),
    bread_items = values(bread_items),
    bread_net = values(bread_net),
    bread_taxable = values(bread_taxable),
    bread_taxexempt = values(bread_taxexempt),
    wings_items = values(wings_items),
    wings_net = values(wings_net),
    wings_taxable = values(wings_taxable),
    wings_taxexempt = values(wings_taxexempt),
    soda_items = values(soda_items),
    soda_net = values(soda_net),
    soda_taxable = values(soda_taxable),
    soda_taxexempt = values(soda_taxexempt),
    other_items = values(other_items),
    other_net = values(other_net),
    other_taxable = values(other_taxable),
    other_taxexempt = values(other_taxexempt),
    nonfood_items = values(nonfood_items),
    nonfood_net = values(nonfood_net),
    nonfood_taxable = values(nonfood_taxable),
    nonfood_taxexempt = values(nonfood_taxexempt),
    delivery_fee_net = values(delivery_fee_net),
    delivery_fee_taxable = values(delivery_fee_taxable),
    delivery_fee_taxexempt = values(delivery_fee_taxexempt),
    service_fee_net = values(service_fee_net),
    service_fee_taxable = values(service_fee_taxable),
    service_fee_taxexempt = values(service_fee_taxexempt),
    smallorder_fee_net = values(smallorder_fee_net),
    smallorder_fee_taxable = values(smallorder_fee_taxable),
    smallorder_fee_taxexempt = values(smallorder_fee_taxexempt),
    tip_fee_net = values(tip_fee_net),
    tip_fee_taxable = values(tip_fee_taxable),
    tip_fee_taxexempt = values(tip_fee_taxexempt),
    breadattach = values(breadattach),
    brokenpromise = values(brokenpromise),
    pickup = values(pickup),
    HNR = values(HNR),
    waiting = values(waiting),
    mobile = values(mobile),
    web = values(web),
    drivethru = values(drivethru),
    portal = values(portal),
    delivery = values(delivery),
    late = values(late),
    failed = values(failed),
    digital = values(digital),
    refund = values(refund),
    system_void = values(system_void),
    void = values(void),
    cash = values(cash),
    credit = values(credit),
    gift_card = values(gift_card),
    cust_account = values(cust_account),
    epay = values(epay),
    other = values(other),
    po = values(po),
    checks = values(checks),
    over_under = values(over_under),
    deposit_dt = values(deposit_dt),
    deposittype = values(deposittype),
    amt = values(amt),
    verify_amt = values(verify_amt),
    manager = values(manager),
    transnum = values(transnum),
    verified = values(verified),
    actual_hours = values(actual_hours),
    scheduled_hours = values(scheduled_hours),
    schedule_charted_hours = values(schedule_charted_hours),
    actual_charted_hours = values(actual_charted_hours),
    inventory_box_variance = values(inventory_box_variance),
    inventory_cheese_variance = values(inventory_cheese_variance),
    customer_complaint = values(customer_complaint),
    hero_notice = values(hero_notice)`
  )
} 
